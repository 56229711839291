<template>
  <div>
    <div
      class="flex items-center w-full relative rounded-2xl hover:cursor-pointer"
      @click="navigate(resource.resourceTranslations[0]?.resourceUrl)"
    >
      <img
        v-if="!isYoutubeLink(resource.resourceTranslations[0]?.resourceUrl)"
        class="rounded-[8px] w-1/2 sm:w-[272px] sm:h-[150px] flex-none"
        :src="resource.thumbnail"
        :alt="resource.title"
      />
      <YoutubeInAppBrowser
        v-else
        :video-id="getYoutubeId(resource.resourceTranslations[0]?.resourceUrl)"
        :video-url="resource.resourceTranslations[0]?.resourceUrl"
        :poster-url="resource.thumbnail"
        :is-dark-button="true"
        :preferred-caption="null"
        customClass="w-full h-[92.1px] xsm:h-[108px] sm:h-[150px] sm:w-[272px] rounded-[8px]"
        class="w-1/2 sm:w-[272px] sm:h-[150px] flex-none"
      />
      <div class="flex-1 flex flex-col justify-center">
        <p
          class="font-aktiv-grotesk-medium leading-[20px] text-[16px] md:text-md lg:text-lg truncate-sm ml-3"
        >
          {{ getTitle() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import ResourcesLanguageModal from "@/components/ResourcesPage/PlaybackResources/ResourcesLanguageModal.vue";
import YoutubeInAppBrowser from "@/components/ResourcesPage/PlaybackResources/YoutubeInAppBrowser.vue";

export default {
  components: {
    ResourcesLanguageModal,
    YoutubeInAppBrowser,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLanguageModal: false,
      downloadTypes: ["PDF", "Excel"],
      articleTypes: ["Link", "Article"],
    };
  },
  computed: {
    modalType() {
      if (this.resource.type === "video") return "video";
      else if (this.downloadTypes.includes(this.resource.type))
        return "download";
      else return "link";
    },
  },
  methods: {
    getTitle() {
      if (this.resource.type === "sm_tools") {
        return this.resource.slug.replace(/-/g, " ");
      } else {
        return this.resource.title;
      }
    },
    openLanguageModal() {
      if (
        this.resource.resourceTranslations &&
        this.resource.resourceTranslations.length > 1
      ) {
        this.showLanguageModal = true;
      }
    },
    closeLanguageModal() {
      this.showLanguageModal = false;
    },
    navigate(url) {
      if (this.isYoutubeLink(url)) return;
      this.openLanguageModal();
      if (!this.showLanguageModal) {
        const translation = this.resource.resourceTranslations[0];
        if (translation.isExternal && !this.isYoutubeLink(translation.resourceUrl)) {
          Browser.open({ url: translation.resourceUrl });
        } else {
          // assume it is a video
          this.$router.push(`/resources/video/${this.resource.slug}`);
        }
      }
    },
    isYoutubeLink(url) {
      return url?.includes("youtube") || url?.includes("youtu.be");
    },
    getYoutubeId(url) {
      return url?.match(
        /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/
      )[1];
    },
  },
};
</script>

<style scoped>
.truncate-sm {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
