<template>
  <master-layout pageTitle="Team">
    <div
      class="w-full flex justify-start items-center pt-[48px] md:pt-[48px] xl:w-[1000px] xl:pt-[80px] pb-[80px] md:pb-[48px] fit-content"
      :class="{ 'pt-[92px]': isIos() }"
    >
      <div
        class="text-center overflow-hidden xl:flex xl:flex-col relative mx-6 md:mx-[108px] xl:ml-[70px] xl:mr-auto xl:min-w-[360px] xl:max-w-[360px] xl:text-left"
      >
        <div
          class="font-aktiv-grotesk-medium mx-auto w-full xl:min-w-[360px] text-center xl:text-left text-[24px] leading-[32px]"
        >
          <span class="font-caveat text-[40px] leading-[32px]">Hello </span>
          from the Global Team!
        </div>
        <div class="flex flex-col">
          <img
            src="../../public/assets/images/team/team.png"
            class="w-auto mt-10 xl:hidden"
          />
        </div>
        <div
          class="text-[18px] leading-[24px] text-left mt-6 font-aktiv-grotesk"
        >
          <p class="text-grey-60">Back row, left to right:</p>
          <p class="text-grey-80">
            <span class="whitespace-nowrap">Pastor Martin Wong | </span>
            <span class="whitespace-nowrap">Blake Gadd | </span>
            <br>
            <span class="whitespace-nowrap">Natascha Brüschweiler | </span>
            <br class="xs:hidden">
            <span class="whitespace-nowrap">Nadine Leong</span>
          </p>
        </div>
        <div
          class="text-[18px] leading-[24px] text-left mt-6 font-aktiv-grotesk"
        >
          <p class="text-grey-60">Front row, left to right:</p>
          <p class="text-grey-80">
            <span class="whitespace-nowrap">Jamie Lee | </span>
            <span class="whitespace-nowrap">Valerie Fifi | </span>
            <br>
            <span class="whitespace-nowrap">Christian Honegger | </span>
            <br class="xs:hidden">
            <span class="whitespace-nowrap">Tan Jian Ming</span>
          </p>
        </div>
        <div class="flex justify-center xl:justify-start">
          <in-app-browser
            href="https://page.heartofgodchurch.org/leadership/"
            target="_blank"
            data-event-tracking="gensapp-team-meetthefullleadershipteam-button"
            @click="logEvent()"
          >
            <div
              class="cursor-pointer px-5 py-3 bg-lightblue text-grey-5 font-aktiv-grotesk-medium text-[20px] leading-[24px] rounded-full mt-10"
            >
              Meet the full leadership team!
            </div>
          </in-app-browser>
        </div>
      </div>
      <img
        src="../../public/assets/images/team/team.png"
        class="xl:w-[504px] xl:h-[380px] hidden xl:block"
      />
    </div>
  </master-layout>
</template>

<script>
import { isIosNativeOnly } from "@/utils/platform-check";
import { StatusBar, Style } from "@capacitor/status-bar";
import { mapState } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "TeamPage",
  computed: {
    ...mapState("user", ["token"]),
  },
  async ionViewDidEnter() {
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Light });
    }
    await this.$store.dispatch("announcements/refreshAnnouncements");
    await this.$store.dispatch("announcements/fetchUnreadCount");
    FirebaseAnalytics.setScreenName({
      screenName: "team",
      nameOverride: "TeamPage",
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    logEvent(){
      FirebaseAnalytics.logEvent({
        name: "click_button", 
        params: {
          "button_name": "gensapp-team-meetthefullleadershipteam-button",
          "page": "schedule"  
        }
      })
    }
  },
};
</script>
<style scoped>
.fit-content {
  height: fit-content;
}
</style>
