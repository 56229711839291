import { createAuth0 } from "@auth0/auth0-vue";
import { createRedirectUri } from "./redirect-uri";
import { isPlatform } from "@ionic/vue";
import router from "@/router";

const redirect_uri = createRedirectUri(
  process.env.VUE_APP_PACKAGE_ID,
  process.env.VUE_APP_AUTH0_DOMAIN
);
console.log("redirect_uri: " + redirect_uri);

const isNative = isPlatform("ios") || isPlatform("android");
console.log("isNative: " + isNative);

const isNativeBrowser = isPlatform("mobileweb");
console.log("isNativeBrowser: " + isNativeBrowser);

export const auth0 = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  },
  cacheLocation: "localstorage",
  useRefreshTokens: true,
});
