<template>
  <div>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="carouselItems"
      :options="swiperOptions"
      @active-index-change="indexIsEnd"
    >
      <div v-html="slotProps.item.html" class="w-screen"></div>
    </BaseSwiper>
  </div>
</template>

<script>
import BaseSwiper from "../common/BaseSwiper";
// import { expData } from "../../utils/exp.js";

export default {
  name: "PreSignInCarousel",
  components: {
    BaseSwiper,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    displayTypes: {
      type: Array,
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
    expData: {
      type: Object,
    },
  },
  data() {
    return {
      // experiences: expData,
      // experiences: this.carouselItems,
      carouselItems: [
        {
          html: '\
          <div>\
            <div class="text-center font-aktiv-grotesk font-semibold text-[1.5rem] md:text-[2rem] leading-[2rem] md:leading-[2.5rem]">Welcome to</div>\
            <img src="/assets/images/gpc-2024-logo.png" class="w-[256px] md:w-[480px] mx-auto"/>\
            <img src="/assets/images/presignin/pre-signin-page-1.png" class="w-auto h-[40vh] mt-[0.75rem] mb-[1.5rem] mx-auto"/>\
            <div class="w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk font-mediumsemi text-[1.25rem] md:text-[1.75rem] leading-[1.75rem] md:leading-[2rem]">\
              Discipling GenerationS to build\
              <span class="whitespace-nowrap md:whitespace-auto">Strong Churches globally</span>\
            </div>\
          </div>',
        },
        {
          html: '\
          <div class="pt-[2rem]">\
            <div class="w-[90vw] md:w-[432px] text-center font-aktiv-grotesk font-semibold text-[1.5rem] md:text-[2rem] leading-[2rem] md:leading-[2.5rem] mx-auto">\
              Time to grow our churches\
              YOUNGER and STRONGER!\
            </div>\
            <img src="/assets/images/presignin/pre-signin-page-2.png" class="w-auto h-[40vh] mt-[4vh] mb-[2vh] mx-auto"/>\
            <div class="w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk font-mediumsemi text-[1.25rem] md:text-[1.75rem] leading-[1.75rem] md:leading-[2rem] mx-auto mt-[1.5rem]">\
              Use this app to sign up for behind-\
              the-scenes Experiences and get\
              the latest conference updates.\
            </div>\
          </div>',
        },
        {
          html: '\
            <div class="pt-[2rem]">\
              <div class="w-[90vw] md:w-[432px] mx-auto text-center font-aktiv-grotesk font-semibold text-[1.5rem] md:text-[2rem] leading-[1.75rem] md:leading-[2.5rem] mx-auto">\
                It doesn’t end with the\
                conference…\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-3.png" class="w-auto h-[40vh] mt-[4vh] mb-[2vh] mx-auto"/>\
              <div class="w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk font-mediumsemi text-[1.25rem] md:text-[1.75rem] leading-[1.75rem] md:leading-[2rem] mx-auto">\
                Continue accessing exclusive\
                GenerationS and Strong Church\
                resources with this app!\
              </div>\
            </div>\
          </div>',
        },
      ],
    };
  },
  methods: {
    update() {
      console.log("update in presignin");
      const swiper = this.$refs[this.baseSwiperId];
      swiper.update();
    },
    indexIsEnd(isEnd) {
      this.$emit("index-is-end", isEnd);
    },
    slideNext() {
      console.log("slide next");
      this.$refs[this.baseSwiperId].next();
    },
  },
};
</script>

<style scoped></style>
