<template>
  <div
    class="relative px-4 py-3 border border-orange border-opacity-25 rounded-2xl"
  >
    <div class="font-aktiv-grotesk-trial mb-3 text-[20px] leading-[24px]">
      <h1 v-html="title"></h1>
    </div>
    <CardLabel v-if="cardLabel" color="orange">{{ cardLabel }}</CardLabel>
    <slot></slot>
  </div>
</template>

<script>
import CardLabel from "../common/CardLabel.vue";
export default {
  components: {
    CardLabel,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    cardLabel: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
