<template>
  <master-layout
    v-slot="slotProps"
    :enable-scroll-event="true"
    @on-scroll="handleScroll"
    id="contentSchedule"
  >
    <!-- TODO Nav Bar -->
    <div
      class="shadow h-[52px] bg-white top-0px lg:top-[88px] fixed w-full lg:w-[calc(100%-292px)] lg:right-0"
      :class="{
        'z-[4]': showNavbar,
        hidden: !showNavbar,
        'pt-[44px] h-[96px]': isIos(),
      }"
    >
      <div
        class="flex justify-start items-center h-[52px] bg-white md:w-[648px] md:mx-auto"
      >
        <DayDropdown :highlightDay="highlightDateStr" @onChange="choose" />
      </div>
    </div>
    <div class="lg:w-full">
      <div
        v-if="type"
        class="top-0 lg:pb-[24px] bg-white md:bg-grey-5 lg:mx-auto"
      >
        <div class="fixed bottom-[88px] z-50">
          <CheatConsole v-if="isNonProd"></CheatConsole>
        </div>
        <!-- top banner section-->
        <div
          id="main-container "
          :class="isGenConf(isWeekday, 'mr-[15px] sm:mr-0')"
        >
          <section
            v-show="!isWeekday"
            class="mb-5 flex relative flex-col item-center h-[210px] pt-[40px] md:pt-12 bg-gens-conf justify-center md:bg-none"
            :class="{ '!pt-[60px] !h-[250px]': isIos() }"
          >
            <img
              id="gens-conf-logo"
              src="../../../public/assets/images/gpc-2024-logo.png"
              class="block md:hidden z-1 w-[260px] mx-auto"
            />
            <img
              id="gens-conf-logo"
              src="../../../public/assets/images/gens-conf-no-bg.svg"
              class="hidden md:block z-1 w-[342px] h-[80px] mx-auto"
            />
            <a class="mx-auto mt-3 mb-2" :href="scheduleLink" target="_blank">
              <button
                id="schedule-gc"
                class="rounded-full w-auto text-white text-center px-4 pt-2 pb-3 bg-lightblue flex gap-x-1 hover:bg-[#303494]"
                data-event-tracking="gensapp-schedule-scheduleataglance"
                @click="logEvent()"
              >
                <img
                  src="../../../public/assets/icons/download-blue.svg"
                  class="w-[21px] h-[22px] self-center"
                />
                <div
                  class="font-aktiv-grotesk-trial text-[20px] leading-[24px] text-center"
                >
                  {{ type === "SCS" ? 5 : 2 }}-Day Schedule
                </div>
              </button>
            </a>
            <px
              class="font-aktiv-grotesk-trial mb-8 text-lightblue text-[12px] leading-[20px] text-center"
            >
              <!-- Also available in 繁体中文 and ไทย -->
            </px>
          </section>
          <section
            v-show="isWeekday"
            class="mb-5 flex relative flex-col item-center h-[210px] pt-[40px] md:pt-12 bg-gens-conf justify-center md:bg-none"
            :class="{ '!pt-[60px] !h-[250px]': isIos() }"
          >
            <img
              id="scs-logo"
              src="../../../public/assets/images/gpc-2024-logo.png"
              class="block md:hidden z-1 w-[260px] mx-auto"
            />
            <img
              id="scs-logo"
              src="../../../public/assets/images/gens-conf-no-bg.svg"
              class="hidden md:block z-1 w-[342px] h-[80px] mx-auto"
            />
            <in-app-browser
              id="schedule-scs"
              class="mx-auto mt-3 mb-2"
              :href="scheduleLink"
              target="_blank"
            >
              <button
                class="rounded-full w-auto text-white text-center px-4 pt-2 pb-3 bg-lightblue flex gap-x-1 hover:bg-[background: #00A9E0]"
                data-event-tracking="gensapp-schedule-scheduleataglance"
              >
                <img
                  src="../../../public/assets/icons/download-blue.svg"
                  class="w-[21px] h-[22px] self-center"
                />
                <div
                  class="font-aktiv-grotesk-trial text-[20px] leading-[24px] text-center"
                >
                  5-Day Schedule
                </div>
              </button>
            </in-app-browser>
            <p
              class="font-aktiv-grotesk-trial mb-8 text-white text-[12px] leading-[20px] md:text-lightblue text-center"
            >
              <!-- Also available in 繁体中文 and ไทย -->
            </p>
          </section>
        </div>
        <!-- Date selection section -->
        <section
          class="bg-white relative z-1 pt-[24px] mt-[-32px] rounded-2xl mt-8 mb-24 md:mx-auto md:w-[648px] lg:w-[648px]"
        >
          <div
            class="flex justify-center w-[320px] xs:w-[335px] mx-auto pb-[24px]"
          >
            <div
              id="conf-dates"
              class="flex justify-center w-[320px] xs:w-[335px] mx-auto"
            >
              <div
                v-for="(card, date) in cards"
                :key="card.id"
                :card="card"
                :class="isGenConf(card.weekday, 'hidden')"
              >
                <div
                  :id="card.dayNum"
                  class="card relative rounded-[100px] mx-[3.5px] py-[11px] w-[50px] xs:w-[60px] border border-opacity-25 transition duration-100 ease-linear cursor-pointer"
                  :class="getDateCardClass(date, card.weekday)"
                  :data-test-id="card.dataTestId"
                  @click="choose(card)"
                >
                  <div
                    class="text-center font-aktiv-grotesk-trial text-[20px] leading-[24px] pb-1"
                  >
                    {{ card.day }}
                  </div>
                  <div
                    class="text-center font-aktiv-grotesk-trial text-[20px] leading-[24px]"
                  >
                    {{ card.dayNum }}
                  </div>
                  <div
                    class="text-center font-aktiv-grotesk text-[16px] leading-[24px]"
                  >
                    {{ card.month }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-[24px]">
            <div class="relative">
              <AnnouncementPanel
                :announcement-str="announcementStr"
              ></AnnouncementPanel>
              <!-- TODO: check for div height and appear -->
              <!-- <div
                class="cursor-pointer w-[32px] h-[32px] rounded-full bg-orange flex items-center justify-center absolute -bottom-4 left-1/2 transform -translate-x-1/2"
                :class="rotationClass"
                data-test-id="gensapp-schedule-cg-experience-expandCard"
                @click="expandCard()"
              >
                <ChevronDownIcon class="text-white" />
              </div> -->
            </div>
          </div>

          <!-- TODO low: Merge this 2 in EventsCard depends on new requirement start -->
          <!-- <div v-if="isSunday" class="mb-[24px] mx-[24px] space-y-6">
            <TimeSeparator
              :now="false"
              :show-time="true"
              :start-time="speStartTime"
              class="mb-[24px]"
            />

            <ServicePrepExperienceCard />

            <CollabConversationCard />
          </div> -->
          <!-- TODO low: Merge this 2 in EventsCard depends on new requirement end -->

          <section class="flex flex-col space-y-6 mb-6">
            <div v-for="(event, i) in eventsWithDate">
              <EventsCard
                v-if="event.type !== 'invited'"
                :id="event.slug"
                :key="i"
                :event-type="event.type"
                :show-time="event.showTime"
                :label="event.label"
                :card-label="event.cardLabel"
                :title="event.title"
                :location="event.location"
                :notes="event.notes"
                :sessionId="event.sessionId ? event.sessionId.sessionId : 0"
                :livevoice="
                  event.sessionId ? event.sessionId.enableLiveVoice : false
                "
                :livevoicelink="
                  event.sessionId ? event.sessionId.liveVoiceLink : ''
                "
                :has-material="
                  event.sessionId
                    ? event.sessionId.totalMaterialsCount > 0
                    : false
                "
                :is-weekday="isWeekday"
                :start-time="event.startTime"
                :now="event.now"
                :table-time-questions="getTableTimeQuestion(event)"
                :has-seating="hasSeating(event)"
                :alumni="event.alumni"
                :toggle-alumni="toggleAlumni"
                :hide-delegate-type="event.hideDelegateType"
                :timing="event.timing"
                :cg-exp="event.cgExp"
                :sub-exp="false"
                :card-description="event.cardDescription"
                :cg-exps="event.cgExps"
                :interest-desc="event.interestDesc"
                :expFindOut="event.expFindOut"
                @show-popup="triggerExpPopup($event, slotProps)"
              />
              <MediaControlRoomCard
                v-else
                :timing="event.timing"
                :location="event.location"
                :id="event.id"
              ></MediaControlRoomCard>
            </div>
          </section>

          <section class="mx-auto font-aktiv-grotesk">
            <EodPanel
              :day="day"
              :is-past-last-day="isPastLastDay"
              class="pb-10"
            ></EodPanel>
            <div>
              <div
                class="flex flex-col cursor-pointer"
                :class="!isWeekday ? 'text-lightblue' : 'text-lightblue'"
                data-event-tracking="gensapp-schedule-backtotop"
                @click="backToTop()"
              >
                <div
                  class="flex flex-col flex-wrap justfiy-center mb-[24px] space-y-2"
                >
                  <img
                    v-if="!isWeekday"
                    src="../../../public/assets/icons/back-to-top-icon-blue.svg"
                  />
                  <img
                    v-else
                    src="../../../public/assets/icons/back-to-top-icon-blue.svg"
                  />
                  <p
                    class="text-center text-[16px] leading-[24px] font-aktiv-grotesk-trial hover:underline"
                  >
                    Back to top
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { ref, onMounted, onBeforeUnmount, toRaw } from "vue";
import moment from "moment";
import momentts from "moment-timezone";
import axios from "axios";
import EventsCard from "../../components/SchedulePage/EventsCard";
import ServicePrepExperienceCard from "../../components/SchedulePage/ServicePrepExperienceCard.vue";
import CollabConversationCard from "../../components/SchedulePage/CollabConversationCard.vue";
import TimeSeparator from "../../components/SchedulePage/TimeSeparator.vue";
import EodPanel from "../../components/HomePage/EodPanel.vue";
import AnnouncementPanel from "../../components/SchedulePage/AnnouncementPanel.vue";
import CheatConsole from "../../components/common/CheatConsole.vue";
import { useAuth0 } from "@auth0/auth0-vue";

import { StatusBar, Style } from "@capacitor/status-bar";
import DayDropdown from "../../components/SchedulePage/DayDropdown.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import store from "@/store/index";
import { isIosNativeOnly } from "@/utils/platform-check";
import { scheduleDaySlugs } from "@/utils/schedulePageSlugs";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import MediaControlRoomCard from "../../components/SchedulePage/MediaControlRoomCard.vue";

export default {
  name: "SchedulePage",
  components: {
    EventsCard,
    ServicePrepExperienceCard,
    CollabConversationCard,
    TimeSeparator,
    EodPanel,
    AnnouncementPanel,
    CheatConsole,
    DayDropdown,
    ChevronDownIcon,
    MediaControlRoomCard,
  },
  data() {
    return {
      show: false,
      events: [],
      cards: {
        "2024-07-11": {
          id: 1,
          day: "Thu",
          dayNum: "11",
          month: "Jul",
          date: "2024-07-11",
          dataTestId: "gensapp-schedule-button-jul-11",
          weekday: true,
          announcement: {
            SCS: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Hello / G’day / Güten tag ~</div>
            📣 Have lunch before coming & grab a jacket!<br>`,
            /*
            📣 <a href="https://collab-conversations.paperform.co" target="_blank" class="text-orange underline font-bold" data-event-tracking="gensapp-schedule-callout-day1-collabconvo-saveslot">SAVE A SLOT</a> for Collab Conversations (Sun)`,
            */
          },
        },
        "2024-07-12": {
          id: 2,
          day: "Fri",
          dayNum: "12",
          month: "Jul",
          date: "2024-07-12",
          dataTestId: "gensapp-schedule-button-jul-12",
          weekday: true,
          announcement: {
            SCS: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Wasn't last night 🤯 ?<br>Here's Day 2!</div>
            📣 Grab a bite before coming!<br>`,
          },
        },
        "2024-07-13": {
          id: 3,
          day: "Sat",
          dayNum: "13",
          month: "Jul",
          date: "2024-07-13",
          dataTestId: "gensapp-schedule-button-jul-13",
          weekday: false,
          announcement: {
            SCS: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">It’s the weekend!</div>
            📣 See you after you’ve had your lunch! 🍔<br>`,
            GC: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">It’s the weekend!</div>
            📣 See you after you’ve had your lunch! 🍔<br>`,
          },
        },
        "2024-07-14": {
          id: 4,
          day: "Sun",
          dayNum: "14",
          month: "Jul",
          date: "2024-07-14",
          dataTestId: "gensapp-schedule-button-jul-14",
          weekday: false,
          announcement: {
            SCS: '<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Sunday is church day!</div>📣 See you after you’ve had your lunch! 🍔',
            GC: '<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Sunday is church day!</div>📣 See you after you’ve had your lunch! 🍔',
          },
        },
        "2024-07-15": {
          id: 5,
          day: "Mon",
          dayNum: "15",
          month: "Jul",
          date: "2024-07-15",
          dataTestId: "gensapp-schedule-button-jul-15",
          weekday: true,
          announcement: {
            SCS: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">We've saved the best for last – don't miss it!</div>📣 Grab a bite before coming!<br>`,
            /*
            📣 <a href="/survey" class="text-orange underline font-bold"
            data-event-tracking="gensapp-schedule-callout-day2-svcprep-signup"
            data-test-id="gensapp-schedule-callout-day5-conference-survey"
            >SURVEY</a> for the Conference<br>`,
            */
          },
        },
      },
      selectedDateStr: "",
      cacheEventsData: {},
      speStartTime: "2023-07-23T02:00:00.000Z",
      seatings: [],
      toggleAlumni: true,
      scheduleTourStep1Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Schedule</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Access the full conference schedule.</span>`,
      scheduleTourStep2Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Schedule (Daily View)</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">View the daily session timings and venues.</span>`,
      scheduleTourStep3Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Session Resources</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Check out “See session resources” and you’re ready for the session!</span>`,
      scheduleDaySlugs: scheduleDaySlugs,
    };
  },
  setup() {
    const { isAuthenticated, user } = useAuth0();

    // nav bar logic - start
    const showNavbar = ref(false);
    let scrollThreshold = 200; // Default scroll threshold

    const handleScroll = async ($event) => {
      const scrollElement = await $event.target.getScrollElement();
      showNavbar.value = scrollElement.scrollTop >= scrollThreshold;
      // this.setScrollPosition(scrollElement.scrollTop);
    };

    const updateScrollThreshold = () => {
      const screenWidth = window.innerWidth;

      // Adjust the scroll threshold based on screen size
      if (screenWidth < 768) {
        scrollThreshold = 366;
      } else if (screenWidth < 1024) {
        scrollThreshold = 316;
      } else {
        scrollThreshold = 316;
      }
    };

    const isIos = isIosNativeOnly;

    onMounted(() => {
      window.addEventListener("resize", updateScrollThreshold);
      updateScrollThreshold();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScrollThreshold);
    });

    // nav bar logic end

    return {
      isAuthenticated,
      user,
      showNavbar,
      handleScroll,
      isIos,
    };
  },
  created() {
    this.$store.dispatch("signup/fetchDates");
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    ...mapGetters("schedule", ["linkOpenDate", "scheduleDates"]),
    ...mapState("signup", ["invitedMcrSignups"]),
    ...mapState("timer", ["currDate"]),
    ...mapState("user", ["token"]),
    ...mapState("profile", ["hasCompletedScheduleTour"]),
    ...mapState("livevoice", ["livevoiceSession, isRegistered"]),
    ...mapState("schedule", ["scrollPosition"]),

    rotationClass() {
      return this.show ? "rotate-180" : "";
    },
    scheduleLink() {
      return this.type === "SCS"
        ? "https://cdn.generationsmvmt.com/docs/GPC2024_Schedule.pdf"
        : "https://cdn.generationsmvmt.com/docs/GenSConf-Translated-Schedule-July-2023-Weekend.pdf";
    },
    eventsWithDate() {
      const timingArray = [];
      const computedEvent = [];
      this.events.forEach((event) => {
        let showTime = true;
        if (timingArray.includes(event.startTime)) {
          showTime = false;
        }
        timingArray.push(event.startTime);
        event.showTime = showTime;
        computedEvent.push(event);
        if (event.id in this.invitedMcrSignups) {
          const signup = this.invitedMcrSignups[event.id];
          computedEvent.push({
            id: "mcr-" + event.id,
            type: "invited",
            timing: signup.expTime,
            location: [signup.location],
          });
        }
      });

      return computedEvent;
    },
    highlightDateStr() {
      if (this.selectedDateStr !== "") return this.selectedDateStr;

      momentts.tz.setDefault("Asia/Singapore");
      let displayStr =
        this.currDate === ""
          ? momentts().format("YYYY-MM-DD")
          : momentts(this.currDate).format("YYYY-MM-DD");
      const dateFirstStr = "2024-07-11";
      const dateLastStr = "2024-07-15";
      const dateFirstStrGC = "2024-07-13";
      const dateLastStrGC = "2024-07-14";

      const display = new Date(displayStr);
      const dateFirst = new Date(dateFirstStr);
      const dateLast = new Date(dateLastStr);
      const dateFirstGC = new Date(dateFirstStrGC);
      const dateLastGC = new Date(dateLastStrGC);

      if (display.getTime() < dateFirst.getTime()) displayStr = dateFirstStr;
      if (display.getTime() > dateLast.getTime()) displayStr = dateLastStr;
      if (display.getTime() < dateFirstGC.getTime() && this.type === "GC")
        displayStr = dateFirstStrGC;
      if (display.getTime() > dateLastGC.getTime() && this.type === "GC")
        displayStr = dateLastStrGC;
      return displayStr;
    },
    isWeekday() {
      return this.cards[this.highlightDateStr].weekday;
    },
    isSunday() {
      return this.cards[this.highlightDateStr].day === "Sun";
    },
    day() {
      const dayHighlighted = this.cards[this.highlightDateStr].id;
      return this.type === "GC" ? dayHighlighted - 2 : dayHighlighted;
    },
    announcementStr() {
      return this.cards[this.highlightDateStr].announcement[this.type];
    },
    seatingMap() {
      if (!this.seatings) return {};
      const map = {};
      this.seatings.forEach((seating) => {
        map[seating.serviceId] = seating.allocation !== "";
      });
      return map;
    },
    isNonProd() {
      return process.env.VUE_APP_NODE_ENV !== "production";
    },
    nativeStatusBarStyle() {
      // const defaultColor = this.cards[this.highlightDateStr].weekday
      //   ? Style.Dark
      //   : Style.Light;

      // return this.showNavbar ? Style.Light : defaultColor;
      return Style.Light;
    },
    isPastLastDay() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);

      // TODO refactor to be dynamic & timezone specific
      const lastDayStr = this.type === "SCS" ? "2024-07-15" : "2024-07-14";
      const lastDay = new Date(lastDayStr);

      return currentDate.getTime() > lastDay.getTime();
    },
    isScheduleAnchorTagPresentInTheUrl() {
      if (this.$route.hash) {
        // slice(0, 5) to get the first 5 digits to determine which day
        return this.scheduleDaySlugs.includes(this.$route.hash.slice(1, 6));
      }
      return false;
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("profile/fetchProfile");
    await this.$store.dispatch("signup/fetchExpData");
    await this.$store.dispatch("signup/fetchMainExpData");

    const card = this.cards[this.highlightDateStr];
    await this.setEventData(card);
    if (this.isIos())
      await StatusBar.setStyle({ style: this.nativeStatusBarStyle });

    const { status, data } = await axios.get(
      process.env.VUE_APP_API_URL + "/attendance/get-user-attendance/",
      {
        params: { serviceIds: "0,1,2,3,4,999" },
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    if (status === 200) this.seatings = data.delegateSeatings;
  },
  updated() {
    if (this.linkOpenDate !== "") {
      const card = this.cards[this.linkOpenDate];
      this.choose(card);
    }

    if (this.scrollPosition !== 0) {
      const elem = document.getElementById("contentSchedule");
      elem.scrollToPoint(0, this.scrollPosition, 1000);
      this.setScrollPosition(0);
    }
  },
  async ionViewDidEnter() {
    await this.$store.dispatch("signup/fetchSignup");
    await this.$store.dispatch("announcements/refreshAnnouncements");
    await this.$store.dispatch("announcements/fetchUnreadCount");
    await this.checkIsRegistered();
    setTimeout(() => {
      if (!this.hasCompletedScheduleTour) {
        if (this.type === "SCS") {
          this.loadSCSTour();
        }
      }
    }, 100);
    if (this.isScheduleAnchorTagPresentInTheUrl) {
      const hash = this.$route.hash;
      await this.chooseDateBasedOn(hash);
      this.scrollToEventBasedOn(hash);
    }
    FirebaseAnalytics.setScreenName({
      screenName: "schedule",
      nameOverride: "SchedulePage",
    });
  },
  watch: {
    async nativeStatusBarStyle(newStyle, oldStyle) {
      if (newStyle != oldStyle && this.isIos())
        await StatusBar.setStyle({ style: newStyle });
    },
  },
  methods: {
    ...mapActions("schedule", ["setLinkOpenDate", "setScrollPosition"]),
    ...mapState("tour", ["setTour"]),

    async checkIsRegistered() {
      return await this.$store.dispatch("livevoice/checkRegistration");
    },

    getDateCardClass(date, weekday) {
      let bgColorCode = "";
      let bgColorHoverCode = "";
      let textColorCode = "";
      let borderColorCode = "";
      let classname = "";

      if (weekday) {
        bgColorCode = "bg-lightblue";
        bgColorHoverCode = "hover:bg-lightblue";
        textColorCode = "text-lightblue hover:text-white";
        borderColorCode = "border-lightblue";
      } else {
        bgColorCode = "bg-lightblue";
        bgColorHoverCode = "hover:bg-lightblue";
        textColorCode = "text-lightblue hover:text-white";
        borderColorCode = "border-lightblue";
      }
      const cardDate = new Date(date);
      const highlightDate = new Date(this.highlightDateStr);

      // auto date card background
      if (cardDate.getTime() === highlightDate.getTime()) {
        const textWhiteColorCode = "text-white";
        classname +=
          " " +
          textWhiteColorCode +
          " " +
          bgColorCode +
          " " +
          borderColorCode +
          " ";
      } else
        classname +=
          " " + textColorCode + " " + borderColorCode + " " + bgColorHoverCode;

      if (this.type === "GC" && weekday) {
        classname += " hidden ";
      }

      return classname;
    },
    expandCard() {
      this.show = !this.show;
    },
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-schedule-scheduleataglance",
          page: "schedule",
        },
      });
    },
    isGenConf(weekday, classText) {
      let className = "";
      if (this.type === "GC" && weekday) {
        className += classText;
      }
      return className;
    },
    async setEventData(card) {
      // cache data in memory
      // if (card.date in this.cacheEventsData) {
      //   const cacheData = this.cacheEventsData[card.date];
      //   const currentTs = new Date().getTime();
      //   const timeElapsed = currentTs - cacheData.lastFetched;
      //   const threeMin = 3 * 60 * 1000;
      //   if (timeElapsed < threeMin) {
      //     this.events = cacheData.data;
      //     return;
      //   }
      // }
      const result = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/schedules/" + card.date,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.cacheEventsData[card.date] = {
        data: result.data,
        lastFetched: new Date().getTime(),
      };
      this.events = result.data;
    },
    backToTop() {
      const elem = document.getElementById("contentSchedule");
      elem.scrollToTop(1000);
    },
    async choose(card) {
      this.backToTop();
      // to-do {need a auto scroll to top function after user selects the date}
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-schedule-day-" + card.id + "-select",
          page: "schedule",
        },
      });
      this.selectedDateStr = card.date;
      this.setLinkOpenDate(this.selectedDateStr);
      await this.setEventData(card);
    },
    hasSeating(event) {
      const serviceId = this.getServiceId(event);
      if (serviceId === null) return false;

      return serviceId in this.seatingMap && this.seatingMap[serviceId];
    },
    getServiceId(event) {
      if (!event.title || !event.label) return null;
      // no the best way to handle, should use type instead of string manipulation since copy can change
      if (event.title.includes("Leaders' Meeting")) return 0;
      if (event.title.includes("Dreams & Stories")) return 999;
      if (event.label.includes("SERVICE")) {
        const labelList = event.label.split(" ");
        return labelList[1];
      }
      return null;
    },
    async skipScheduleTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-skip-schedule",
          page: "schedule",
        },
      });
      const payload = { hasCompletedScheduleTour: true };
      await this.$store.dispatch("profile/setScheduleTourStatus", payload);
      store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    async endScheduleTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-end-schedule",
          page: "schedule",
        },
      });
      const payload = { hasCompletedScheduleTour: true };
      await this.$store.dispatch("profile/setScheduleTourStatus", payload);
      store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    startScheduleTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-start-schedule",
          page: "schedule",
        },
      });
      this.tour.next();
    },
    loadSCSTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: "bg-yellow",
          cancelIcon: {
            enabled: true,
          },
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              progress.innerText = `Step ${
                this.tour.steps.indexOf(currentStep) + 1
              } of ${this.tour.steps.length}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = { hasCompletedScheduleTour: true };
              store.dispatch("profile/setScheduleTourStatus", payload);
              store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "schedule-scs",
          attachTo: {
            element: "#schedule-scs",
            on: "bottom",
          },
          buttons: [
            {
              text: "Skip",
              action: this.skipScheduleTour,
              secondary: true,
            },
            {
              text: "Next",
              action: this.startScheduleTour,
            },
          ],
          highlightClass: "highlight",
          text: this.scheduleTourStep1Text,
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
        },
        {
          id: "dates-scs",
          attachTo: {
            element: "#conf-dates",
            on: "bottom",
          },
          buttons: [
            {
              text: "Back",
              action: this.tour.back,
              secondary: true,
            },
            {
              text: "Next",
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.scheduleTourStep2Text,
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
        },
        {
          id: "materials-scs",
          attachTo: {
            element: ".materials-card",
            on: "bottom",
          },
          text: this.scheduleTourStep3Text,
          buttons: [
            {
              text: "Back",
              action: this.tour.back,
              secondary: true,
            },
            {
              text: "End",
              action: this.endScheduleTour,
            },
          ],
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
        },
      ];

      this.tour.addSteps(steps);
      this.tour.start();
      this.choose(this.cards["2024-07-11"]);
      this.setTour(this.tour);
    },

    getTableTimeQuestion(event) {
      if (!event.hasTableTimeQuestion) return "";

      const defaultHtml = `
        <p class="text-[14px] font-aktiv-grotesk-medium text-grey-80">*Includes Discussion Time</p>
        <div class="text-[14px] text-grey-60">
          Set actionable steps with your team
        </div>
      `;

      if (!event.sessionId) return defaultHtml;
      if (!event.sessionId.questions.length) return defaultHtml;
      return (
        '<p class="text-[14px] font-aktiv-grotesk-medium text-grey-80">Discussion Time</p>' +
        event.sessionId.questions[0].description
      );
    },
    triggerExpPopup(exp, slotProps) {
      console.log("in triggerExpPopup");
      slotProps.toggleExp(exp, false);
    },
    async chooseDateBasedOn(hash) {
      // slice(5, 6) to get the digit of which day it is, e.g. 1 for day 1
      const dayIndex = parseInt(hash.slice(5, 6), 10);
      const date = this.scheduleDates[dayIndex - 1];
      await this.choose(this.cards[date]);
    },
    scrollToEventBasedOn(hash) {
      // substring(1) to remove the # in the hash
      const eventToScrollTo = document.getElementById(hash.substring(1));
      if (eventToScrollTo) {
        eventToScrollTo.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}
</style>
