<template>
  <button>
    <img src="../../../../public/assets/icons/playback-resources/share-button.svg" alt="" @click="toggleShowSharing">
  </button>
</template>

<script>
export default {
  methods: {
    toggleShowSharing() {
      this.$emit('toggleSharing')
    }
  }
}
</script>

<style>

</style>