<template>
  <section
    class="bg-gens-conf-yellow-bar rounded-2xl mx-6 md:mx-auto md:w-[520px] flex flex-col md:flex-row justify-center items-center px-10 py-7 mb-16"
  >
    <img
      src="../../../public/assets/images/home/playlist.png"
      class="w-[247px] md:hidden"
    />
    <img
      src="../../../public/assets/images/home/album-logo.png"
      class="w-[220px] md:w-[184px] mt-3 md:mr-6"
    />
    <div class="md:flex md:flex-col md:justify-center md:items-center">
      <img
        src="../../../public/assets/images/home/playlist.png"
        class="w-[247px] hidden md:block"
      />
      <in-app-browser href="https://gens.app/music" target="_blank">
        <button
          class="rounded-full bg-black py-3 px-5 text-white mt-7 md:mt-4 font-aktiv-grotesk-medium text-[20px] leading-[24px]"
          data-event-tracking="gensapp-home-playlist"
          @click="logEvent()"
        >
          Available On Spotify
        </button>
      </in-app-browser>
    </div>
  </section>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import InAppBrowser from "../InAppBrowser.vue";
export default {
  components: { InAppBrowser },
  methods: {
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-playlist",
          page: "home",
        },
      });
    },
  },
};
</script>

<style scoped></style>
