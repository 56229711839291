import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MasterLayout from "./components/MasterLayout.vue";
import { createLogoutUri, redirectDeepLinkPath } from "./utils/redirect-uri";
import VueSocialSharing from "vue-social-sharing";
import VueShepherd from "vue-shepherd";
import { Browser } from "@capacitor/browser";
import {
  isNativeNotWeb,
  isAndroidNativeOnly,
  isIosNativeOnly,
} from "./utils/platform-check";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "tailwindcss/base.css";
import "tailwindcss/components.css";
import "tailwindcss/utilities.css";

/* Theme variables */
import "./theme/variables.css";

/* TailwindCSS */
import "./styles/main.scss";

import { auth0 } from "./utils/auth0";
import HtmlToVue from "./components/common/HtmlToVue.vue";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import OneSignal from "onesignal-cordova-plugin";
import InAppBrowser from "./components/InAppBrowser.vue";
import youtubePlayer from "./utils/youtube-player";

const app = createApp(App)
  .use(IonicVue)
  .use(auth0)
  .use(router)
  .use(store)
  .use(VueSocialSharing)
  .use(VueShepherd)
  .use(youtubePlayer)

  .component("master-layout", MasterLayout)
  .component("html-to-vue", HtmlToVue)
  .component("in-app-browser", InAppBrowser);

// if (process.env.VUE_APP_DATADOG_RUM_APP_ID) {
//   datadogRum.init({
//     applicationId: process.env.VUE_APP_DATADOG_RUM_APP_ID,
//     clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     service: process.env.VUE_APP_DATADOG_SERVICE,
//     env: process.env.VUE_APP_NODE_ENV,
//     allowedTracingOrigins: [process.env.VUE_APP_DATADOG_TRACING_ORIGIN],

//     // Specify a version number to identify the deployed version of your application in Datadog
//     version: "1.0.0",
//     sampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: "mask-user-input",
//   });
//   datadogRum.startSessionReplayRecording();

//   window.$datadogRum = datadogRum;
// }

// TODO: add youtube player
// app.config.globalProperties.$youtubePlayer = youtubePlayer

if (process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.VUE_APP_DATADOG_SERVICE,
    env: process.env.VUE_APP_NODE_ENV,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}

router.isReady().then(() => {
  if (process.env.VUE_APP_NODE_ENV === "local") {
    const token = localStorage.getItem("token");
    if (token) store.commit("user/setToken", token);
    app.mount("#app");
    return;
  }

  auth0
    .getAccessTokenSilently({ cacheMode: "cache-only" })
    .then((token) => {
      store.commit("user/setToken", token);
    })
    .catch((err) => {
      if (err.error === "login_required") {
        auth0.logout({
          logoutParams: {
            returnTo: createLogoutUri(
              process.env.VUE_APP_PACKAGE_ID,
              process.env.VUE_APP_AUTH0_DOMAIN
            ),
          },
          openUrl: async (url) =>
            await Browser.open({
              url,
              windowName: "_self",
            }),
        });
      }
      console.log("in access token error");
      console.error(err);
    })
    .finally(() => {
      app.mount("#app");
      if (isNativeNotWeb() && process.env.VUE_APP_ONESIGNAL_API_KEY) {
        OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_API_KEY);
        OneSignal.Notifications.requestPermission();
        OneSignal.Notifications.addEventListener("click", async (event) => {
          console.log("The notification was clicked!", event);

          const isInternal = redirectDeepLinkPath(
            router,
            event.notification.launchURL
          );
          if (!isInternal && isIosNativeOnly()) {
            console.log("external url: ", event.notification.launchURL);
            await Browser.open({ url: event.notification.launchURL });
          }
        });
      }
    });
});

app.config.errorHandler = async function (err, vm, info) {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in error handler");
    console.error(err);
  }

  // TODO datadog error monitoring
  if (err.message === "Request failed with status code 401") {
    await auth0.logout({
      logoutParams: {
        returnTo: createLogoutUri(
          process.env.VUE_APP_PACKAGE_ID,
          process.env.VUE_APP_AUTH0_DOMAIN
        ),
      },
      openUrl: async (url) =>
        await Browser.open({
          url,
          windowName: "_self",
        }),
    });
  }
};

window.onerror = function (message, source, lineno, colno, error) {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in window error handler");
    console.log(message);
    console.error(error);
  }
  // TODO datadog error monitoring
};

window.addEventListener("unhandledrejection", async (event) => {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in unhandled rejecction");
    console.error(event);
    console.log(event.reason.message);
  }
  if (
    event.reason &&
    event.reason.message &&
    (event.reason.message === "Request failed with status code 401" ||
      event.reason.message === "Login required" ||
      event.reason.message.startsWith("Missing Refresh Token") ||
      event.reason.message.startsWith("Unknown or invalid refresh token"))
  ) {
    await auth0.logout({
      logoutParams: {
        returnTo: createLogoutUri(
          process.env.VUE_APP_PACKAGE_ID,
          process.env.VUE_APP_AUTH0_DOMAIN
        ),
      },
      openUrl: async (url) =>
        await Browser.open({
          url,
          windowName: "_self",
        }),
    });
  }
  // TODO datadog error monitoring
});
