<template>
  <header>
    <div
      v-for="i in [0, 1]"
      :key="i"
      class="bg-gens-conf-yellow-bar bg-repeat md:flex items-start lg:items-center justify-between fixed top-0 left-0 right-0 z-[30] h-[88px] hidden"
      :class="{
        fixed: i,
        'relative invisible': !i,
      }"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    >
      <router-link to="/">
        <div class="ml-[40px] my-[20px] inline-flex items-center">
          <img
            src="../../../public/assets/images/gpc-2024-logo.png"
            class="w-[138px] h-[49px]"
            alt="gens conf logo"
          />
        </div>
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CommonHeader',
  components: {},
  computed: {
    ...mapGetters('profile', ['type']),
  },
}
</script>

<style scoped></style>
