<template>
  <master-layout id="profile-edit">
    <div
      :class="{
        'top-0': !showHeader,
        'top-navbar-height': showHeader,
      }"
    >
      <div class="lg:w-[calc(100vw-292px)]">
        <div
          id="profileBanner"
          class="h-[200px] w-full bg-contain bg-dont"
          :class="{ '!h-[240px]': isIos() }"
        >
          <div
            class="z-10 relative top-[16px] left-[24px]"
            :class="{ '!top-[60px]': isIos() }"
          >
            <img
              src="../../../public/assets/icons/go-back-chevron.svg"
              alt="Back Icon"
              class="cursor-pointer"
              data-test-id="gensapp-experiencesignup-back"
              @click="back()"
            />
          </div>
          <div class="z-1 mt-[35px]" :class="{ '!mt-[79px]': isIos() }">
            <img src="../../../public/assets/images/profile-logo-desktop.png" class="hidden lg:block w-[930px] mx-auto">
            <img src="../../../public/assets/images/profile-logo-mobile.png" class="lg:hidden w-[351px] mx-auto">
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div
          class="z-10 w-[327px] lg:w-full mt-[-48px] lg:mt-[-48px] px-[48px] inline-flex lg:flex flex-col items-center"
        >
          <div class="flex flex-col gap-[24px]">
            <FileUploader />
            <div
              v-for="(value, key, index) in details"
              :key="index"
              class="w-[327px] lg:w-full"
            >
              <div
                v-html="value.description"
                class="w-full h-[20px] font-semibold leading-[20px] font-['Proxima Nova'] mb-2"
              ></div>
              <div>
                <input
                  :ref="key"
                  type="text"
                  :value="getDetailFormVariable(key)"
                  @input="updateDetail(key, $event.target.value)"
                  class="w-full pl-2 py-2 border rounded-md font-aktiv"
                  :class="
                    isNameEmpty(key) ? 'focus-color-red' : 'focus-color-blue'
                  "
                />
              </div>
              <div
                v-if="isNameEmpty(key)"
                class="italic text-[#B80A0A] text-[12px] leading-[20px]"
              >
                Let us know your name!
              </div>
            </div>
          </div>
          <div class="w-[327px] lg:w-full mt-[8px]">
            <Toggle
              :value="formEmailFlag"
              @input="updateEmailFlag"
              class="gap-[10px] justify-end w-full"
            >
              <template #left>Show my email</template>
            </Toggle>
          </div>
          <div class="w-[327px] lg:w-[680px] h-[64px] my-[24px]">
            <div
              class="w-full h-[20px] font-semibold leading-[20px] font-['Proxima Nova'] mb-2"
            >
              Account login email
            </div>
            <div
              class="w-full pl-2 py-2 border border-color-grey-40 rounded-md font-aktiv bg-[#F8F9F9] text-[16px] leading-[28px] text-grey-40"
            >
              {{ email }}
            </div>
          </div>
          <div class="w-[327px] lg:w-[680px]">
            <div class="w-full font-semibold leading-[20px] mb-[48px]">
              Add Socials
              <div
                v-for="(value, key, index) in socials"
                :key="index"
                class="relative mt-3"
              >
                <input
                  type="text"
                  :value="getSocialFormVariable(key)"
                  @input="updateSocial(key, $event.target.value)"
                  class="w-full pl-10 py-2 border rounded-md focus:border-blue-500 focus:ring-1 focus:ring-blue-500 font-aktiv"
                  :placeholder="value.placeholder"
                />
                <div
                  class="absolute inset-y-0 left-0 pl-2 pr-3 flex items-center pointer-events-none"
                >
                  <img :src="value.icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="w-[327px] lg:w-full h-[48px] pb-[160px]">
            <button
              class="font-aktiv-grotesk-trial block w-full mx-auto max-w-[295px] rounded-[100px] bg-lightblue text-white text-[20px] leading-[24px] text-center py-[12px] mb-4"
              @click="saveProfile()"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import QrcodeVue from "qrcode.vue";
import Toggle from "@/components/common/Toggle.vue";
import FileUploader from "@/components/Profile/FileUploader.vue";
import social from "@/store/modules/social";
import axios from "axios";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { StatusBar, Style } from "@capacitor/status-bar";

export default {
  name: "Profile",
  components: {
    QrcodeVue,
    // Collapsible,
    Toggle,
    FileUploader,
  },
  data() {
    return {
      details: {
        name: {
          description: `Display Name <span style="font-style:italic;color:#637083;">(required)</span>`,
          value: "formName",
        },
        organization: {
          description: "Church / Organization",
          value: "formOrganization",
        },
        title: {
          description: "Title",
          value: "formTitle",
        },
        email: {
          description: "Email",
          value: "formEmail",
        },
      },
      socials: {
        instagram: {
          value: "formInstagram",
          placeholder: "Add Instagram",
          icon: require("../../../public/assets/icons/icon-instagram-default.svg"),
        },
        facebook: {
          value: "formFacebook",
          placeholder: "Add Facebook",
          icon: require("../../../public/assets/icons/icon-facebook-default.svg"),
        },
        x: {
          value: "formX",
          placeholder: "Add X",
          icon: require("../../../public/assets/icons/icon-x-default.svg"),
        },
        linkedin: {
          value: "formLinkedIn",
          placeholder: "Add LinkedIn",
          icon: require("../../../public/assets/icons/icon-linkedin-default.svg"),
        },
      },
      nameEmpty: false,
    };
  },
  computed: {
    ...mapState("social", [
      "formName",
      "formOrganization",
      "formTitle",
      "formEmail",
      "formEmailFlag",
      "formInstagram",
      "formFacebook",
      "formX",
      "formLinkedIn",
      "formPicture",
      "uploadURL",
    ]),
    ...mapGetters("profile", ["email"]),

    showHeader() {
      return this.$route.path !== "/home";
    },
  },
  async mounted() {
    if (navigator.userAgent.match(/samsung/i)) {
      this.isSamsung = true;
      console.log("You're samsung");
    } else {
      console.log("You're not samsung");
    }
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  },
  async ionViewDidEnter() {
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    FirebaseAnalytics.setScreenName({
      screenName: "profile - edit",
      nameOverride: "ProfilePage - Edit",
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    isNameEmpty(key) {
      return this.nameEmpty && key === "name";
    },
    back() {
      this.$store.commit("social/resetFormData");
      this.$router.push("/profile");
    },
    getDetailFormVariable(detailType) {
      if (detailType == "name") {
        return this.formName;
      } else if (detailType == "organization") {
        return this.formOrganization;
      } else if (detailType == "title") {
        return this.formTitle;
      } else if (detailType == "email") {
        return this.formEmail;
      }
    },
    updateDetail(detailType, newDetailInput) {
      if (detailType == "name") {
        this.nameEmpty = false;
        this.$store.commit("social/setFormName", newDetailInput);
      } else if (detailType == "organization") {
        this.$store.commit("social/setFormOrganization", newDetailInput);
      } else if (detailType == "title") {
        this.$store.commit("social/setFormTitle", newDetailInput);
      } else if (detailType == "email") {
        this.$store.commit("social/setFormEmail", newDetailInput);
      }
    },
    getSocialFormVariable(socialType) {
      if (socialType == "instagram") {
        return this.formInstagram;
      } else if (socialType == "facebook") {
        return this.formFacebook;
      } else if (socialType == "x") {
        return this.formX;
      } else if (socialType == "linkedin") {
        return this.formLinkedIn;
      }
    },
    updateSocial(socialType, newSocialInput) {
      if (socialType == "instagram") {
        this.$store.commit("social/setFormInstagram", newSocialInput);
      } else if (socialType == "facebook") {
        this.$store.commit("social/setFormFacebook", newSocialInput);
      } else if (socialType == "x") {
        this.$store.commit("social/setFormX", newSocialInput);
      } else if (socialType == "linkedin") {
        this.$store.commit("social/setFormLinkedIn", newSocialInput);
      }
    },
    updateEmailFlag(newValue) {
      this.$store.commit("social/setFormEmailFlag", newValue);
    },
    async saveProfile() {
      if (this.formName == null || this.formName == "") {
        console.log("in IF statement");
        this.nameEmpty = true;
        this.backToTop();
        console.log(this.$refs.name);
        this.$refs.name[0].focus();
      } else {
        console.log("in ELSE statement");
        // only execute if there is a new image to upload
        if (this.formPicture) {
          //file upload function
          // 1. get the uploadURL (full presigned URL by AWS)
          await this.$store.dispatch("social/getUploadURL");

          //  2. call awsAxios upload to S3 bucket
          // file = state.pictureObj
          // Uploads files to AWS according to presigned url
          const awsAxios = axios.create();
          delete awsAxios.defaults.headers.common.Authorization;
          await awsAxios.put(this.uploadURL, this.formPicture, {
            headers: { "Content-Type": "application/octet-stream" },
          });
        }
        await this.$store.dispatch("social/updateSocialProfile");
        this.$router.push("/profile");
      }
    },
    backToTop() {
      const elem = document.getElementById("profile-edit");
      elem.scrollToTop(1000);
    },
  },
};
</script>
<style scoped>
.focus-color-blue:focus {
  outline: none;
  border: 1px solid rgb(59 130 246);
}
.focus-color-red:focus {
  outline: none;
  border: 1px solid #dd3333;
}

@media (min-width: 769px) {
  #profileBanner {
    background-image: url("../../../public/assets/images/bg-profileDesktop.png");
    background-size: 100% 100%; /* Stretch image to cover entire area */
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center; /* Center the background image */
  }
}

@media (max-width: 768px) {
  #profileBanner {
    background-image: url("../../../public/assets/images/bg-profileMobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
