<template>
  <div class="relative w-full pr-3">
    <img
      src="../../../public/assets/icons/search.svg"
      class="absolute left-5 top-1/2 transform -translate-y-1/2 w-6 h-6"
      alt="Search Icon"
    />
    <img
      src="../../../public/assets/icons/cross.svg"
      class="absolute right-7 top-1/2 transform -translate-y-1/2 w-3 h-3 hover:cursor-pointer"
      alt="Cross Icon"
      @click="clearSearch"
    />
    <input
      v-model="localSearchTerm"
      type="text"
      class="text-[#637083] w-full pl-12 py-2 bg-white border-[#637083] text-20 leading-32 font-aktiv-grotesk rounded-lg border focus:shadow-outline outline-none"
      placeholder="Search"
      @input="onSearch"
    />
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  props: {
    assets: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localSearchTerm: this.searchTerm,
      ResultsCount: 0,
      searchResults: {
        links: [],
        pdf: [],
        books: [],
        quotes: [],
      },
      isSearching: false,
    };
  },
  watch: {
    searchTerm(newTerm) {
      this.localSearchTerm = newTerm;
      this.onSearch();
    },
    localSearchTerm(newTerm) {
      this.$emit("update:searchTerm", newTerm);
    },
  },
  methods: {
    onSearch() {
      // console.log("STARTING", this.assets);
      console.log(this.assets);
      const fuseLink = new Fuse(this.assets.links, {
        keys: ["title", "searchTerms", "description", "preacher", "topics"],
        minMatchCharLength: 3,
        threshold: 0.1,
        ignoreLocation: true,
      });
      const fusePdf = new Fuse(this.assets.pdf, {
        keys: ["slug", "searchTerms", "topics"],
        minMatchCharLength: 3,
        threshold: 0.1,
        ignoreLocation: true,
      });
      const fuseBooks = new Fuse(this.assets.books, {
        keys: ["title", "searchTerms", "description", "topics"],
        minMatchCharLength: 3,
        threshold: 0.1,
        ignoreLocation: true,
      });
      const fuseQuotes = new Fuse(this.assets.quotes, {
        keys: ["title", "searchTerms", "description", "topics"],
        minMatchCharLength: 3,
        threshold: 0.1,
        ignoreLocation: true,
      });
      const fuseLinkResult = fuseLink.search(this.localSearchTerm);
      const fusePdfResult = fusePdf.search(this.localSearchTerm);
      const fuseBooksResult = fuseBooks.search(this.localSearchTerm);
      const fuseQuotesResult = fuseQuotes.search(this.localSearchTerm);

      // Data transformation
      const pdfResult = fusePdfResult.map((result) => {
        return {
          ...result.item,
        };
      });
      const linksResult = fuseLinkResult.map((result) => {
        return {
          ...result.item,
        };
      });
      const booksResult = fuseBooksResult.map((result) => {
        return {
          ...result.item,
        };
      });
      const quotesResult = fuseQuotesResult.map((result) => {
        return {
          ...result.item,
        };
      });
      this.searchResults = {
        links: linksResult,
        pdf: pdfResult,
        books: booksResult,
        quotes: quotesResult,
      };
      this.ResultsCount =
        linksResult.length +
        pdfResult.length +
        booksResult.length +
        quotesResult.length;
      // console.log("Search Results: ", this.searchResults);
      if (this.searchTerm.trim() === "") {
        this.isSearching = false;
      } else {
        this.isSearching = true;
      }
      this.$emit(
        "submit-search",
        this.searchResults,
        this.localSearchTerm,
        this.ResultsCount,
        this.isSearching
      );
    },
    clearSearch() {
      this.localSearchTerm = "";
      this.searchResults = {
        links: [],
        pdf: [],
        books: [],
        quotes: [],
      };
      this.ResultsCount = 0;
      this.isSearching = false;
      this.$emit(
        "submit-search",
        this.searchResults,
        this.localSearchTerm,
        0,
        this.isSearching
      );
    },
  },
};
</script>

<style scoped>
.searchBar {
  color: #637083;
  border-width: 1px;
  border-radius: 10px;
  outline-style: none;
}

.searchBar:focus {
  box-shadow: 0 0 2pt 2pt rgba(0, 102, 255, 0.8);
}
</style>
