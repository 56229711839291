<template>
  <PopupModal :show="show" @close-popup="closeModal()">
    <div
      class="bg-white text-grey-100 max-w-[calc(100%-48px)] xs:max-w-[327px] lg:max-w-[480px] lg:w-[480px] max-content text-[24px] leading-[32px] text-center font-aktiv-grostek rounded-2xl m-auto fixed inset-0 box-shadow shadow-[0_1px_25px_rgba(0,0,0,0.25) z-50 p-6"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
      @click.stop
    >
      <img
        src="../../../public/assets/icons/popup-close.svg"
        class="absolute w-[16px] h-[16px] right-6 top-6 cursor-pointer"
        data-test-id="gensapp-close-download-modal"
        @click="closeModal()"
      />
      <div
        class="font-aktiv-grotesk-medium text-left text-[24px] leading-[32px]"
      >
        Download video
      </div>
      <div
        v-for="download in downloads"
        :key="download.quality"
        class="flex flex-row justify-between pt-[18px]"
      >
        <div class="text-left">
          <div class="font-aktiv-grotesk-trial text-[16px] leading-[24px]">
            {{ download.quality }}
          </div>
          <div class="text-[10px] leading-[12px]">
            {{ download.fileSize }}
          </div>
        </div>
        <a
          :href="download.url"
          class="cursor-pointer"
          download
          data-event-tracking="gensapp-home-kickoff-download-video-1080p"
          :data-test-id="download.dataTestId"
          @click="logEvent()"
        >
          <img
            src="../../../public/assets/icons/download-grey-outline-blue.svg"
            class="w-10 h-10"
          />
        </a>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from '@/components/common/PopupModal'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: 'DownloadModal',
  components: {
    PopupModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloads: [
        {
          quality: '1080p',
          fileSize: '153 MB',
          resolution: '1920 x 1080',
          url: 'https://cdn.generationsmvmt.com/videos/conference-app/strong_church_summit_pre-watch.mp4',
          dataTestId: 'gensapp-home-kickoff-download-video-1080p',
        }
      ],
    }
  },
  methods: {
    logEvent(){
      FirebaseAnalytics.logEvent({
        name: "click_button", 
        params: {
          "button_name": "gensapp-home-kickoff-download-video-1080p",
          "page": "home"  
        }
      })
    },
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>

<style scoped>
.max-content {
  height: max-content;
}
</style>
