<template>
  <Pill link="" text="Giving" @click-trigger="givingWebView" />
</template>

<script>
import Pill from "./Pill.vue";
import { Browser } from "@capacitor/browser";
import { mapState } from "vuex";
export default {
  name: "GivingSection",
  components: {
    Pill,
  },
  methods: {
    givingWebView() {
      Browser.open({
        url: "https://gens.app/give?prefilled_email=" + this.email,
        // windowName: "_self",
      });
    },
  },
  computed: {
    ...mapState("profile", ["email"]),
  },
};
</script>

<style></style>
