<template>
  <Pill link="" text="App Guided Tour" data-event-tracking="gensapp-home-restart-tour" @click-trigger="resetTour" />
</template>

<script>
import Pill from "./Pill.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "GuidedTourSection",
  components: { Pill },
  methods: {
    async resetTour(e){
      FirebaseAnalytics.logEvent({
        name: "click_button", 
        params: {
          "button_name": "gensapp-home-restart-tour",
          "page": "home"  
        }
      })
      const homeTourStatus = {hasCompletedHomepageTour: false}
      await this.$store.dispatch('profile/setHomeTourStatus', homeTourStatus)
      const scheduleTourStatus = {hasCompletedScheduleTour: false}
      await this.$store.dispatch('profile/setScheduleTourStatus', scheduleTourStatus)
      const experienceTourStatus = {hasCompletedExperienceTour: false}
      await this.$store.dispatch('profile/setExperienceTourStatus', experienceTourStatus)
      this.$emit('reset-tour')
    }
  }
}
</script>

<style></style>
