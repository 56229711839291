<template>
  <div
    class="relative rounded-2xl overflow-hidden border-[1px] border-green border-solid p-[16px] pt-8"
  >
    <div class="font-aktiv-grotesk-trial mb-3">
      {{ title }}
    </div>
    <div v-if="date" class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-date-icon-orange.svg"
        alt="icon"
        class="mt-[2px]"
      />
      <div>
        <p class="ml-2">
          {{ date }}
        </p>
      </div>
    </div>
    <div v-if="expTime" class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-duration-icon-orange.svg"
        alt="icon"
        class="mt-[2px]"
      />
      <div>
        <p class="ml-2">
          {{ expTime }}
        </p>
      </div>
    </div>
    <div
      v-if="location && location.length > 0"
      class="flex mb-1 justify-start items-start"
    >
      <img
        src="../../../public/assets/icons/event-location-icon-orange.svg"
        alt="icon"
        class="mt-1 ml-1"
      />
      <div class="flex flex-col">
        <p v-for="locate in location" :key="locate" class="ml-2">
          {{ locate }}
        </p>
      </div>
    </div>

    <CardLabel color="green">Confirmed</CardLabel>
    <div v-if="title === 'Dreams & Stories Night'" class="flex justify-end">
      <in-app-browser
        href="https://cdn.generationsmvmt.com/docs/Dream-and-Stories-Night-Floorplan.pdf"
        target="_blank"
      >
        <div
          class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline text-orange"
        >
          View floorplan
        </div>
      </in-app-browser>
    </div>
    <div
      v-else-if="location && location.length > 0 && location[0].includes('Hub')"
      class="flex justify-end"
    >
      <in-app-browser
        href="https://cdn.generationsmvmt.com/docs/HOGC-Map-2024.pdf"
        target="_blank"
      >
        <div
          class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline text-orange"
        >
          View map
        </div>
      </in-app-browser>
    </div>
  </div>
</template>

<script>
import CardLabel from "../common/CardLabel.vue";
import InAppBrowser from "../InAppBrowser.vue";
export default {
  components: { CardLabel, InAppBrowser },
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    expTime: {
      type: String,
    },
    location: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>

<style></style>
