<template>
  <div
  class="bg-cover h-[60px] overflow-hidden "
  :class="getBreakColor"
  ></div>
</template>

<script>
export default {
  name: 'BreakCard',
  props: {
    isWeekday: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getBreakColor() {
      return this.isWeekday ? 'bg-break-wide-blue' : 'bg-break-wide-blue'
    },
  },
}
</script>

<style scoped></style>
