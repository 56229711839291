<template>
  <PopupModal :show="show" @close-popup="closeModal()">
    <div
      class="bg-white w-[327px] lg:w-[480px] h-[252px] lg:h-[300px] text-[24px] leading-[32px] text-center font-aktiv-grostek-medium rounded-2xl m-auto fixed inset-0 box-shadow shadow-[0_1px_25px_rgba(0,0,0,0.25) z-50 mx-auto pt-[56px]"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
      @click.stop
    >
      <img
        src="../../../public/assets/icons/popup-close.svg"
        class="absolute w-[16px] h-[16px] right-6 top-6 cursor-pointer"
        data-test-id="gensapp-close-logout-modal"
        @click="closeModal()"
      />
      <div
        class="pb-6 text-grey-100 font-aktiv-grotesk-medium text-[24px] leading-[32px]"
      >
        Do you want to log out?
      </div>
      <div
        class="flex flex-col w-[284px] mx-auto text-[20px] leading-[24px] mb-[32px]"
      >
        <button
          class="rounded-full w-full font-aktiv-grotesk-medium text-grey-5 text-center text-[20px] leading-[24px] px-5 py-3 bg-lightblue mb-3"
          data-event-tracking="gensapp-home-logoutpopup-continue"
          data-test-id="gensapp-home-logoutpopup-continue"
          @click="logoutUser()"
        >
          Continue
        </button>
        <button
          class="rounded-full w-full border-2 border-grey-70 font-aktiv-grotesk-medium text-grey-70 text-center text-[20px] leading-[24px] px-5 py-3"
          data-event-tracking="gensapp-home-logoutpopup-cancel"
          data-test-id="gensapp-home-logoutpopup-cancel"
          @click="closeModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from "@/components/common/PopupModal.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { createRedirectUri, createLogoutUri } from "../../utils/redirect-uri";
import OneSignal from "onesignal-cordova-plugin";
import { isNativeNotWeb } from "@/utils/platform-check";

export default {
  components: { PopupModal },
  data() {
    const { logout } = useAuth0();
    return {
      logout,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    async logoutUser() {
      if (process.env.VUE_APP_NODE_ENV === "local") {
        localStorage.removeItem("token");
        this.$router.push("/login");
      } else {
        if (isNativeNotWeb() && process.env.VUE_APP_ONESIGNAL_API_KEY) {
          OneSignal.logout();
        }
        await this.logout({
          logoutParams: {
            returnTo: createLogoutUri(
              process.env.VUE_APP_PACKAGE_ID,
              process.env.VUE_APP_AUTH0_DOMAIN
            ),
          },
          openUrl: async (url) =>
            await Browser.open({
              url,
              windowName: "_self",
            }),
        });
      }
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.25);
}
</style>
