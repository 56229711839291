<template>
  <div
    class="day-tabs w-full lg:w-[calc(100vw-292px)] flex py-6 text-grey-80 font-aktiv-grotesk-medium overflow-x-auto pl-4 md:justify-center bg-white"
  >
    <button
      :class="[
        'py-2 px-4 mx-2 rounded-xl border-2 border-solid flex-shrink-0 ',
        {
          'border-lightblue text-lightblue font-bold':
            selectedMyPhotoTypeTab !== 'exp',
          'border-transparent': selectedMyPhotoTypeTab === 'exp',
        },
      ]"
      @click="changeDayTab('personal')"
    >
      Personal
    </button>
    <button
      :class="[
        'py-2 px-4 mx-2 rounded-xl border-2 border-solid flex-shrink-0 ',
        {
          'border-lightblue text-lightblue font-bold':
            selectedMyPhotoTypeTab === 'exp',
          'border-transparent': selectedMyPhotoTypeTab !== 'exp',
        },
      ]"
      @click="changeDayTab('exp')"
    >
      Custom Experiences
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PhotoMyPhotoTypeTab",
  data() {
    return {};
  },
  computed: {
    ...mapState("photos", ["selectedMyPhotoTypeTab"]),
  },
  methods: {
    ...mapActions("photos", ["setSelectedMyPhotoTypeTab"]),
    async changeDayTab(selected) {
      const currentCount = this.changeDayTabFuncCount;
      this.setSelectedMyPhotoTypeTab(selected);
      this.$emit("refresh-photo");
    },
  },
};
</script>

<style></style>
