<template>
  <div
    class="w-full h-full flex flex-col content-center bg-grey-10 text-black overflow-hidden lg:rounded-xl"
    :class="{ 'pt-12': isIos || isAndroid }"
  >
    <div
      class="h-[32px] flex flex-row justify-between items-center mx-[24px] mt-[11.5px] mb-5"
    >
      <button @click="closeFilterModal">
        <img src="../../../public/assets/icons/close.svg" alt="" />
      </button>
      <h2 class="font-bold font-aktiv-grotesk text-[20px] leading-[25px]">
        {{ filterType }}
      </h2>
      <p
        class="cursor-pointer font-aktiv-grotesk text-[14px] leading-[20px] text-[#00A9E0] font-semibold"
        @click="resetFilters"
      >
        Reset
      </p>
    </div>

    <div
      class="accordion-wrapper h-full overflow-x-hidden overflow-y-scroll mx-[16px]"
    >
      <div v-if="showSessionsFilter">
        <div
          v-for="session in schedule"
          :key="session.id"
          class="checkbox mb-[8px] rounded-lg"
          :class="[
            { ' bg-[#e7f7ff]': selectedSessions.includes(session.id) },
            { ' bg-[#f8f9f9]': !selectedSessions.includes(session.id) },
          ]"
          @click="toggleSelectedSession(session.id)"
        >
          <label
            :for="session.id"
            class="flex items-center h-full relative cursor-pointer pl-[16px] py-[8px] text-[16px] leading-[24px]"
            :style="{ width: 'calc(100% - 52px)' }"
          >
            {{ getTitle(session) }}
            <input
              :id="session.id"
              class="hidden absolute"
              type="checkbox"
              :name="session.title"
              :value="session.id"
              :checked="selectedSessions.includes(session.id)"
              @click.stop
            />
            <div v-if="selectedSessions.includes(session.id)">
              <img
                class="absolute top-1/2 right-[-36px] w-[20px] h-[20px] rounded-sm transform -translate-y-1/2"
                src="../../../public/assets/icons/checked-icon.png"
              />
            </div>
            <div v-if="!selectedSessions.includes(session.id)">
              <img
                class="absolute top-1/2 right-[-36px] w-[20px] h-[20px] rounded-sm transform -translate-y-1/2"
                src="../../../public/assets/icons/unchecked-icon.png"
              />
            </div>
            <!-- <span
              class="checkmark absolute top-1/2 right-[-36px] w-[20px] h-[20px] bg-transparent border border-[#00A9E0] rounded-sm transform -translate-y-1/2"
            ></span> -->
          </label>
        </div>
      </div>

      <div v-if="showTopicsFilter">
        <div
          v-for="topic in topics"
          :key="topic.name"
          class="checkbox mb-[8px] rounded-lg"
          :class="[
            { ' bg-[#e7f7ff]': selectedTopics.includes(topic.topic) },
            { ' bg-[#f8f9f9]': !selectedTopics.includes(topic.topic) },
          ]"
          @click="toggleSelectedTopic(topic.topic)"
        >
          <div>
            <label
              :for="topic.topic"
              class="flex items-center h-full relative cursor-pointer pl-[16px] py-[8px] text-[16px] leading-[24px]"
              :style="{ width: 'calc(100% - 52px)' }"
            >
              {{ topic.topic }}
              <input
                :id="topic.topic"
                class="hidden absolute"
                type="checkbox"
                :name="topic.topic"
                :value="topic.topic"
                :checked="selectedTopics.includes(topic.topic)"
                @click.stop
              />
              <div v-if="selectedTopics.includes(topic.topic)">
                <img
                  class="absolute top-1/2 right-[-36px] w-[20px] h-[20px] rounded-sm transform -translate-y-1/2"
                  src="../../../public/assets/icons/checked-icon.png"
                />
              </div>
              <div v-if="!selectedTopics.includes(topic.topic)">
                <img
                  class="absolute top-1/2 right-[-36px] w-[20px] h-[20px] rounded-sm transform -translate-y-1/2"
                  src="../../../public/assets/icons/unchecked-icon.png"
                />
              </div>
              <!-- <span
                class="checkmark absolute top-1/2 right-[-36px] w-[20px] h-[20px] bg-transparent border border-[#00A9E0] rounded-sm transform -translate-y-1/2"
              ></span> -->
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-[24px]">
      <BaseButton
        class="w-full h-[48px] justify-center items-center font-aktiv-grotesk leading-[24px] text-[18px] text-center py-[12px] text-white font-bold mt-[16px] mb-[100px] lg:mb-[16px]"
        @click="applyFilters"
        solid-classes="bg-[#00A9E0] text-white"
        :disabled="isFilterEmpty"
      >
        Apply filters
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment-timezone";
import { isIosNativeOnly, isAndroidNativeOnly } from "@/utils/platform-check";
import BaseButton from "../common/BaseButton.vue";
import FilterAccordion from "./FilterAccordion.vue";

export default {
  components: {
    BaseButton,
    FilterAccordion,
  },
  data() {
    return {
      selectedSessions: [],
      selectedTopics: [],
    };
  },
  computed: {
    ...mapState("filter", [
      "sessions",
      "topics",
      "schedule",
      "filteredSessions",
      "filteredTopics",
      "allResources",
      "filterType",
    ]),
    ...mapState("cms", ["loading"]),
    ...mapGetters("schedule", ["scheduleDates"]),
    ...mapGetters("profile", ["type"]),
    isIos() {
      return isIosNativeOnly();
    },
    isFilterEmpty() {
      return this.selectedSessions.length < 1 && this.selectedTopics.length < 1
    },
    isAndroid() {
      return isAndroidNativeOnly();
    },
    showSessionsFilter() {
      if (this.filterType == "Sessions") {
        return true;
      }
      return false;
    },
    showTopicsFilter() {
      if (this.filterType == "Topics") {
        return true;
      }
      return false;
    },
  },
  async created() {
    await this.$store.dispatch("filter/fetchSchedule");
  },
  mounted() {
    this.selectedSessions = Object.assign([], this.filteredSessions);
    this.selectedTopics = Object.assign([], this.filteredTopics);
  },
  methods: {
    async closeFilterModal() {
      const filterType = this.filterType;
      const showFilterModal = false;
      await this.$store.dispatch("filter/updateShowFilterModal", {
        showFilterModal,
        filterType,
      });
    },
    toggleSelectedTopic(topic) {
      if (this.selectedTopics.includes(topic)) {
        const index = this.selectedTopics.indexOf(topic);
        this.selectedTopics.splice(index, 1);
      } else {
        this.selectedTopics.push(topic);
      }
    },
    toggleSelectedSession(sessionId) {
      if (this.selectedSessions.includes(sessionId)) {
        const index = this.selectedSessions.indexOf(sessionId);
        this.selectedSessions.splice(index, 1);
      } else {
        this.selectedSessions.push(sessionId);
      }
    },
    async setFilters() {
      await this.$store.dispatch("filter/updateFilteredValues", {
        filteredSessions: this.selectedSessions,
        filteredTopics: this.selectedTopics,
      });
    },
    async applyFilters() {
      this.setFilters();
      await this.$store.dispatch("filter/updateFilteredMaterials");
      this.closeFilterModal();
      await this.$store.dispatch("filter/updateShowFilterResults", true);
      if (this.$route.path === "/resources/sessions") {
        this.$router.push("/resources/sessions/filter");
      }
    },
    resetFilters() {
      this.selectedSessions = [];
      this.selectedTopics = [];
      this.setFilters();
    },
    getDay(date) {
      moment.tz.setDefault("Asia/Singapore");
      const dateObj = moment(new Date(date));
      const dateString = dateObj.format("YYYY-MM-DD");
      const day = this.scheduleDates.indexOf(dateString) + 1;
      return this.type === "SCS" ? day : day - 2;
    },
    getTitle(session) {
      return "Day " + this.getDay(session.startTime) + ": " + session.title;
    },
  },
};
</script>

<style scoped>
.accordion-wrapper::-webkit-scrollbar,
.accordion::-webkit-scrollbar {
  display: none;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: rgb(255, 255, 255, 0.5);
}

/* When the checkbox is checked, add a yellow background */
.checkbox input:checked ~ .checkmark {
  background-color: #00a9e0;
  border-color: #00a9e0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
/* .checkbox input:checked ~ .checkmark:after */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid#f7f7f7;
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
