const INITIAL_STATE = {
  linkOpenDate: "",
  scrollPosition: 0,
  scheduleDates: [
    "2024-07-11",
    "2024-07-12",
    "2024-07-13",
    "2024-07-14",
    "2024-07-15",
  ],
};

const state = () => INITIAL_STATE;

const mutations = {
  setLinkOpenDate(state, newLinkOpenDate) {
    state.linkOpenDate = newLinkOpenDate;
  },
  setScrollPosition(state, newScrollPosition) {
    state.scrollPosition = newScrollPosition;
  },
};

const actions = {
  setLinkOpenDate(context, linkOpenDate) {
    context.commit("setLinkOpenDate", linkOpenDate);
  },
  setScrollPosition(context, scrollPosition) {
    context.commit("setScrollPosition", scrollPosition);
  },
};

const getters = {
  linkOpenDate(state) {
    return state.linkOpenDate;
  },
  scrollPosition(state) {
    return state.scrollPosition;
  },
  scheduleDates(state) {
    return state.scheduleDates;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
