import markerSDK from "@marker.io/browser";
import axios from "axios";
import { startSmartlook } from "../../utils/smartlook";
import { startSurvicate } from "@/utils/survicate";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FirebaseCrashlytics } from "@awesome-cordova-plugins/firebase-crashlytics";
import { isPlatform } from "@ionic/vue";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import OneSignal from "onesignal-cordova-plugin";

// import { useAuth0 } from '@auth0/auth0-vue';

const INITIAL_STATE = {
  id: undefined,
  name: "",
  hashId: "",
  preferredName: "",
  type: "",
  userType: "",
  auth0Id: "",
  auth0IdPrefixed: "",
  email: { email: undefined },
  organization: "",
  loading: false,
  hasCompletedHomepageTour: false,
  hasCompletedScheduleTour: false,
  hasCompletedExperienceTour: false,
  signupCheck: true,
  needLiveVoice: false,
  fcmToken: "",
  channels: [],
  confYear: "",
  currentYear: "2024-G",
};

const state = () => INITIAL_STATE;

const mutations = {
  setProfileState(state, newProfile) {
    for (const key in newProfile) state[key] = newProfile[key];
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
};

const actions = {
  async fetchProfile({ dispatch, state }) {
    // Will not call backend api if profile already set
    if (state.name) {
      if (process.env.VUE_APP_NODE_ENV === "staging") {
        markerSDK.loadWidget({
          destination: process.env.VUE_APP_MARKERIO_DESTINATION, // <- Your unique destination ID
          reporter: {
            email: state.email,
            fullName: state.name,
          },
        });
      }
      return;
    }
    await dispatch("forceFetchProfile");
    /* window.$crisp.push(['set', 'user:email', [state.email]])
     window.$crisp.push(['set', 'user:nickname', [state.name]])
     window.$datadogRum.setUser({
      name: state.name,
      email: state.email,
      organization: state.organization,
      type: state.type,
    })
    window.$datadogRum.setUserProperty('organization', state.organization)
    window.$datadogRum.setUserProperty('type', state.type)
    */
  },

  async forceFetchProfile({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    try {
      const isNative = isPlatform("ios") || isPlatform("android");
      const isNativeBrowser = isPlatform("mobileweb");
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/profile",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in setting profile");
      commit("setProfileState", data);
      if (data.confYear !== rootState.profile.currentYear) {
        $crisp.push(["do", "chat:hide"]);
      }
      window.$crisp.push(["set", "user:email", [data.email]]);
      window.$crisp.push(["set", "user:nickname", [data.name]]);

      // if (!isNative && isNativeBrowser) {
      //   window.$datadogRum.setUser({
      //     name: data.name,
      //     email: data.email,
      //   });
      //   window.$datadogRum.setUserProperty("organization", data.organization);
      //   window.$datadogRum.setUserProperty("type", data.type);
      // }
      const userProps = {
        delegateEmail: data.email,
        delegateName: data.name,
        churchName: data.organization,
        teamID: data.groupName,
        year: data.confYear.split("-")[0],
        userType: data.userType,
        delegateType:
          data.organization === "Heart of God Church" ? "Host" : "Delegate",
      };
      window.dataLayer.push({
        event: "login",
        userId: data.id,
        userType: data.type,
        userOrganization: data.organization,
        userEmail: data.email,
        ...userProps,
      });

      /**
       * Platform: Web/Android/iOS
       * Sets a user property to a given value.
       * @param options - property name and value to set
       * @returns void
       * https://firebase.google.com/docs/analytics/user-properties
       */
      FirebaseAnalytics.setUserId({
        userId: data.id,
      });
      FirebaseAnalytics.setUserProperty({
        name: "user_identifier",
        value: data.id.toString(),
      });
      FirebaseAnalytics.setUserProperty({
        name: "email",
        value: data.email,
      });
      FirebaseAnalytics.setUserProperty({
        name: "user_organization",
        value: data.organization,
      });
      FirebaseAnalytics.setUserProperty({
        name: "team_id",
        value: data.groupName,
      });
      FirebaseAnalytics.setUserProperty({
        name: "conference_year",
        value: data.confYear.split("-")[0],
      });
      FirebaseAnalytics.setUserProperty({
        name: "user_type",
        value:
          data.organization === "Heart of God Church" ? "Host" : "Delegate",
      });

      if (isNative && !isNativeBrowser) {
        App.getInfo().then(function (res) {
          window.$crisp.push(["set", "session:data", ["app_build", res.build]]);
          window.$crisp.push([
            "set",
            "session:data",
            ["app_version", res.version],
          ]);
        });
        Device.getInfo().then(function (res) {
          window.$crisp.push([
            "set",
            "session:data",
            ["platform", res.platform],
          ]);
          window.$crisp.push([
            "set",
            "session:data",
            ["operatingSystem", res.operatingSystem],
          ]);
          window.$crisp.push([
            "set",
            "session:data",
            ["osVersion", res.osVersion],
          ]);
        });
        FirebaseAnalytics.logEvent({
          name: "native_login",
          params: {
            user_id: data.id,
            ...userProps,
          },
        });
        OneSignal.login("GPC-" + data.id);
        // OneSignal.setEmail(data.email);
        OneSignal.User.addTags({ confYear: data.confYear, ...userProps });
      }
      // FirebaseCrashlytics.setUserId(data.email)

      if (process.env.NODE_ENV === "staging") {
        markerSDK.loadWidget({
          destination: process.env.VUE_APP_MARKERIO_DESTINATION, // <- Your unique destination ID
          reporter: {
            email: data.email,
            fullName: data.name,
          },
        });
      }
      const { email, name, organization, groupName, confYear } = data;
      startSmartlook(email, name, organization, groupName, confYear);
      startSurvicate(
        email,
        name,
        organization,
        process.env.VUE_APP_SURVICATE_KEY
      );
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setHomeTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/profile/set-homepage-tour",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting homepage tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setScheduleTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/profile/set-schedule-tour",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting schedule tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setExperienceTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/profile/set-experience-tour",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting experience tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setFcmToken({ commit, dispatch, rootState }, payload) {
    console.log("setFcmToken here...");
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/profile/set-fcm-token",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting fcm token");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  name(state) {
    return state.preferredName && state.preferredName !== ""
      ? state.preferredName
      : state.name;
  },
  delegateId(state) {
    return state.id;
  },
  email(state) {
    return state.email;
  },
  type(state) {
    return state.type;
  },
  auth0Id(state) {
    return state.auth0Id;
  },
  payload(state) {
    if (!state.name) {
      return {
        name: undefined,
        organization: undefined,
        type: undefined,
        auth0Id: undefined,
      };
    }
    return {
      name:
        (state.preferredName && state.preferredName) !== ""
          ? state.preferredName
          : state.name,
      organization: state.organization,
      type: state.type,
      email: state.email,
      auth0Id: state.auth0Id,
    };
  },
  SIGNUP_CLOSE_DATE(state) {
    return state.SIGNUP_CLOSE_DATE;
  },
  isAbsentAlumni(state) {
    return state.confYear !== state.currentYear;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
