import axios from "axios";

const state = () => ({
  isLoading: false,
  allResources: [],
  allQuotes: [],
  allBooks: [],
  flattenedAllResources: [],
  flattenedQuotes: [],
  flattenedBooks: [],
  sessions: [],
  topics: [],
  filteredSessions: [],
  filteredTopics: [],
  showFilterModal: false,
  showFilterResults: false,
  schedule: [],
  searchSuggestions: [],
  filterType: "",
});

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setAllResources(state, allResources) {
    state.allResources = allResources;
  },
  setAllQuotes(state, allQuotes) {
    state.allQuotes = allQuotes;
  },
  setAllBooks(state, allBooks) {
    state.allBooks = allBooks;
  },
  setFlattenedAllResources(state, flattenedAllResources) {
    state.flattenedAllResources = flattenedAllResources;
  },
  setFlattenedQuotes(state, flattenedQuotes) {
    state.flattenedQuotes = flattenedQuotes;
  },
  setFlattenedBooks(state, flattenedBooks) {
    state.flattenedBooks = flattenedBooks;
  },
  setSessions(state, sessions) {
    state.sessions = sessions;
  },
  setTopics(state, topics) {
    state.topics = topics;
  },
  setSchedule(state, schedule) {
    state.schedule = schedule;
  },
  setSearchSuggestions(state, searchSuggestions) {
    state.searchSuggestions = searchSuggestions;
  },
  setShowFilterModal(state, { showFilterModal, filterType }) {
    state.showFilterModal = showFilterModal;
    state.filterType = filterType;
  },
  setFilteredValues(state, { filteredSessions, filteredTopics }) {
    state.filteredSessions = filteredSessions;
    state.filteredTopics = filteredTopics;
  },
  setShowFilterResults(state, showFilterResults) {
    state.showFilterResults = showFilterResults;
  },
  setFilteredMaterials(state) {
    if (state.filterType === "Sessions") {
      const filteredSessionIds = state.filteredSessions;
      const filteredResources = state.flattenedAllResources.filter(
        (resource) => {
          const isSessionsMatch =
            filteredSessionIds.length > 0
              ? filteredSessionIds.some((id) => resource.sessionId === id)
              : true;
          return isSessionsMatch;
        }
      );
      state.filteredResources = filteredResources;

      const filteredQuotes = state.flattenedQuotes.filter((resource) => {
        const isSessionsMatch =
          filteredSessionIds.length > 0
            ? filteredSessionIds.some((id) => resource.sessionId === id)
            : true;
        return isSessionsMatch;
      });
      state.filteredQuotes = filteredQuotes;

      const filteredBooks = state.flattenedBooks.filter((resource) => {
        const isSessionsMatch =
          filteredSessionIds.length > 0
            ? filteredSessionIds.some((id) => resource.sessionId === id)
            : true;
        return isSessionsMatch;
      });
      state.filteredBooks = filteredBooks;
    } else if (state.filterType === "Topics") {
      const filteredTopics = state.filteredTopics;
      const filteredResources = state.flattenedAllResources.filter(
        (resource) => {
          const isTopicsMatch =
            filteredTopics.length > 0
              ? filteredTopics.some((topic) => {
                  if (resource.hasOwnProperty("topics")) {
                    for(const key in resource.topics){
                      if(resource.topics[key]===topic){
                        return true
                      }
                    }
                  }
                })
              : true;
          return isTopicsMatch;
        }
      );
      state.filteredResources = filteredResources;

      const filteredQuotes = state.flattenedQuotes.filter((resource) => {
        const isTopicsMatch =
          filteredTopics.length > 0
            ? filteredTopics.some((topic) => {
                if (resource.hasOwnProperty("topics")) {
                  for(const key in resource.topics){
                    if(resource.topics[key]===topic){
                      return true
                    }
                  }
                }
              })
            : true;
        return isTopicsMatch;
      });
      state.filteredQuotes = filteredQuotes;

      const filteredBooks = state.flattenedBooks.filter((resource) => {
        const isTopicsMatch =
          filteredTopics.length > 0
            ? filteredTopics.some((topic) => {
                if (resource.hasOwnProperty("topics")) {
                  for(const key in resource.topics){
                    if(resource.topics[key]===topic){
                      return true
                    }
                  }
                }
              })
            : true;
        return isTopicsMatch;
      });
      state.filteredBooks = filteredBooks;
    }
    state.showFilterModal = true;
    state.showFilterResults = true;
  },
};

const actions = {
  async fetchAllResources({ state, dispatch, commit, rootState }) {
    commit("setIsLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: allresources } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/resources/all",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const allQuotes = allresources.filter(
        (resource) => resource.name === "Quotes"
      );
      const allBooks = allresources.filter(
        (resource) => resource.name === "Book"
      );
      const allResources = allresources.filter(
        (resource) => resource.name != "Quotes" && resource.name != "Book"
      );
      commit("setAllResources", allResources);
      commit("setAllQuotes", allQuotes);
      commit("setAllBooks", allBooks);

      const resourceList = [];
      for (let material of allResources) {
        for (let resource of material.resources) {
          Object.defineProperty(resource, "name", { value: material.name });
          resourceList.push(resource);
        }
      }
      commit("setFlattenedAllResources", resourceList);

      const quoteList = [];
      for (let material of allQuotes) {
        for (let resource of material.resources) {
          quoteList.push(resource);
        }
      }

      const bookList = [];
      for (let material of allBooks) {
        for (let resource of material.resources) {
          bookList.push(resource);
        }
      }
      commit("setFlattenedQuotes", quoteList);
      commit("setFlattenedBooks", bookList);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch session materials.", {
        root: true,
      });
    } finally {
      commit("setIsLoading", false);
    }
  },

  async fetchTopics({ state, dispatch, commit, rootState }) {
    commit("setIsLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: topics } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/filter/topics",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      topics.sort((a, b) => a.order - b.order);
      commit("setTopics", topics);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch topics.", {
        root: true,
      });
    } finally {
      commit("setIsLoading", false);
    }
  },
  async fetchSchedule({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/schedules/session/all",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting schedule");
      commit("setSchedule", data);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch schedule.", {
        root: true,
      });
    }
  },
  async fetchSearchSuggestions({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/search/keywords",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting search keywords");
      commit("setSearchSuggestions", data);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch schedule.", {
        root: true,
      });
    }
  },

  updateShowFilterModal({ commit }, { showFilterModal, filterType }) {
    commit("setShowFilterModal", { showFilterModal, filterType });
  },

  updateFilteredValues({ commit }, { filteredSessions, filteredTopics }) {
    commit("setFilteredValues", { filteredSessions, filteredTopics });
  },
  updateFilteredMaterials({ commit }) {
    commit("setFilteredMaterials");
  },

  updateShowFilterResults({ commit }, showFilterResults) {
    commit("setShowFilterResults", showFilterResults);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
