<template>
  <PopupModal
    :show="show"
    :enable-click-away="false"
    @close-popup="closeModal()"
  >
    <div
      v-if="show"
      class="fixed bg-white rounded-2xl top-[calc(50%-44px)] left-1/2 transform -translate-y-1/2 -translate-x-1/2 px-[10px] xs:px-6 pt-[32px] pb-[32px] flex flex-col z-50 font-aktiv-grotesk-medium w-[300px] xs:w-auto max-w-[343px]"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    >
      <div class="mb-8 flex justify-center">
        <img
          class="h-[64px] w-[64px]"
          alt="Email"
          src="../../../public/assets/icons/email.svg"
        />
      </div>
      <h1
        class="text-[24px] leading-[32px] font-aktiv-grotesk-medium text-center mb-4"
      >
        <span class="xs:whitespace-nowrap">Get download link in email</span>
      </h1>
      <p class="text-[16px] leading-[24px] font-aktiv-grotesk text-center mb-8">
        A link will be sent to your email to download the selected videos and/or photos.
      </p>
      <div
        class="font-aktiv-grotesk-trial w-full mx-auto max-w-[295px] rounded-[100px] text-white text-[20px] leading-[24px] text-center py-[12px] mb-6 bg-orange hover:bg-[#F27521] cursor-pointer"
        @click="download()"
      >
        Send link to my email
      </div>
      <div
        class="text-[20px] leading-[24px] text-center font-aktiv-grotesk-trial cursor-pointer text-orange"
        @click="closeModal()"
      >
        Cancel
      </div>
    </div>
  </PopupModal>
</template>

<script>
import axios from "axios"
import PopupModal from "../common/PopupModal.vue";
import { mapState } from "vuex";

export default {
  name: "PhotoDownloadModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    ...mapState("photos", [
      "selectedPhotos",
    ]),
    ...mapState("user", ["token"]),
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    download() {
      const { status, data } = axios.request(
        process.env.VUE_APP_API_URL + "/photo/multi-download",
        {
          method: "post",
          data: { presignedUrls: this.selectedPhotos },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.closeModal()
    }
  },
  components: { PopupModal },
};
</script>

<style scoped></style>
