<template>
  <div class="pt-6">
    <div class="flex flex-wrap items-center justify-between mb-3">
      <h2 class="flex-1 text-[16px] font-aktiv-grotesk-medium">
        {{ date !== "" ? formatDate(date) : formatExpType(expType) }}
      </h2>
      <div
        v-if="isSelectingPhotos"
        class="flex font-aktiv-grotesk-regular text-[14px] leading-5 cursor-pointer items-center"
        @click="selectAllInGallery"
      >
        Select all
        <img
          v-if="isAllPhotosSelected"
          class="ml-2 md:ml-4 w-[20px] md:w-[28px] h-[20px] md:h-[28px] border border-[#00A857] rounded-full"
          src="/assets/icons/blue-tick.svg"
          alt=""
        />

        <span
          v-else
          class="ml-2 md:ml-4 w-[20px] md:w-[28px] h-[20px] md:h-[28px] bg-white border border-lightblue rounded-full"
        />
      </div>
    </div>

    <div :id="galleryId" class="flex gap-2 flex-wrap hidden">
      <a
        v-for="(photo, key) in links"
        :key="key"
        :href="photo"
        data-pswp-width="106"
        data-pswp-height="106"
        data-pswp-type="image"
        target="_blank"
        rel="noreferrer"
      >
      </a>
    </div>
    <div
      class="tiles grid gap-x-1 gap-y-2 pb-2 auto-rows-fr min-w-[106px] min-h-[106px] md:min-w-[200px] md:min-h-[200px]"
    >
      <label
        v-for="(image, linkIndex) in links"
        :key="linkIndex"
        class="relative cursor-pointer"
        data-test-id="gensapp-photodrop-selectphotos"
        @click="selectPhotos(image, linkIndex)"
      >
        <img
          class="block w-[106px] h-[106px] md:w-[200px] md:h-[200px] object-cover"
          :src="image"
        />

        <img
          v-if="isSelectingPhotos && isPhotoSelected(image)"
          class="absolute block bottom-2 right-2 w-[20px] md:w-[28px] h-[20px] md:h-[28px] border border-[#00A857] rounded-full"
          src="/assets/icons/blue-tick.svg"
          alt=""
        />

        <span
          v-if="isSelectingPhotos && !isPhotoSelected(image)"
          class="absolute bottom-2 right-2 block w-[20px] md:w-[28px] h-[20px] md:h-[28px] bg-white border border-lightblue rounded-full"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import { StatusBar, Style } from "@capacitor/status-bar";
import Tooltip from "../icons/Tooltip.vue";
import PhotoPopup from "@/components/Photo/PhotosPopup";
import isIos from "@/mixins/isIos";
import PhotoSwipeLightbox from "../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipe from "../../../node_modules/photoswipe/dist/photoswipe.esm.js";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import moment from "moment";
import momentts from "moment-timezone";

export default {
  components: {
    PhotoPopup,
    Tooltip,
  },
  mixins: [isIos],
  props: {
    date: {
      type: String,
      default: "",
    },
    expType: {
      type: String,
      default: "",
    },
    links: {
      type: Array,
      required: true,
    },
    viewportWidth: {
      type: Number,
      required: true,
    },
    // hasTooltip: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      showingTooltip: false,
      tooltipHover: false,

      lightbox: null,
      options: {
        index: 0,
        pinchToClose: false,
        closeOnScroll: false,
        closeOnVerticalDrag: false,
        preload: [1, 2],
        closeEl: true,
        captionEl: false,
        fullscreenEl: false,
        zoomEl: true,
        shareEl: false,
        counterEl: true,
        arrowEl: false,
        preloaderEl: true,
        spacing: 0.6,
        allowPanToNext: true,
        clickToCloseNonZoomable: false,
        arrowKeys: false,
        getDoubleTapZoom: (isMouseClick, item) => {
          if (isMouseClick) {
            return 1.5;
          } else {
            return item.initialZoomLevel < 0.7 ? 1 : 1.5;
          }
        },

        gallery: "#pictureGallery",
        children: "a",
        pswpModule: PhotoSwipe,
      },
    };
  },
  computed: {
    ...mapState("photos", ["isSelectingPhotos", "selectedPhotos"]),
    ...mapState("profile", ["id"]),
    ...mapState("signup", ["expData"]),
    isAllPhotosSelected() {
      let isSelected = true;
      this.links.forEach((link) => {
        isSelected = isSelected && this.selectedPhotos.includes(link);
      });

      return isSelected;
    },
    galleryId() {
      return "myGallery-" + this.date;
    },
  },
  watch: {
    showingTooltip(value) {
      if (value) {
        window.addEventListener("mousedown", this.outsideClick);
      } else {
        window.removeEventListener("mousedown", this.outsideClick);
      }
    },
    viewportWidth(width) {
      if (width >= 1024) {
        this.showingTooltip = false;
      }
    },
  },
  mounted() {
    const lightbox = new PhotoSwipeLightbox(this.options);
    lightbox.on("uiRegister", function () {
      const logEvent = function (pathName) {
        const pos = pathName.lastIndexOf("/");
        const fileName = pathName.substring(pos + 1);
        try {
          FirebaseAnalytics.logEvent({
            name: "click_button",
            params: {
              button_name: "gensapp-photodrop-gallery-download",
              page: "photoDrop",
              fileName: fileName,
            },
          });
        } catch (e) {}
        try {
          window.dataLayer.push({
            event: "gensapp-photodrop-gallery-download-web",
            file_name: fileName,
          });
        } catch (e) {}
      };
      lightbox.pswp.ui.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",

        html: '<svg class="pswp__download__btn" width="32" height="32" viewBox="0 0 32 32" fill="none"><g clip-path="url(#clip0_10929_39313)"><rect width="32" height="32" rx="16" fill="#FFD900"/><g clip-path="url(#clip1_10929_39313)"><path d="M8.99976 20.375V22.125C8.99976 22.5891 9.18413 23.0342 9.51232 23.3624C9.84051 23.6906 10.2856 23.875 10.7498 23.875H21.2498C21.7139 23.875 22.159 23.6906 22.4872 23.3624C22.8154 23.0342 22.9998 22.5891 22.9998 22.125V20.375" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.6248 15.125L15.9998 19.5L20.3748 15.125" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 9V19.5" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/></g></g><defs><clipPath id="clip0_10929_39313"><rect width="32" height="32" rx="16" fill="white"/></clipPath><clipPath id="clip1_10929_39313"><rect x="5.5" y="5.5" width="21" height="21" fill="white"/></clipPath></defs></svg>',

        onInit: (el, pswp) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");
          el.setAttribute(
            "data-event-tracking",
            "gensapp-photodrop-gallery-download-web"
          );
          pswp.on("change", () => {
            // console.log('change');
            el.removeEventListener("click", logEvent(el.href));
            el.href = pswp.currSlide.data.src;
            el.addEventListener("click", logEvent(el.href));
          });
        },
      });
    });
    lightbox.init();
    lightbox.on("openingAnimationStart", async () => {
      if (isIosNativeOnly()) {
        const pswp = document.getElementsByClassName("pswp__top-bar");
        pswp[0].style.height = "104px";
        pswp[0].style.paddingTop = "44px";
        await StatusBar.setStyle({ style: Style.Dark });
      }
    });
    lightbox.on("closingAnimationStart", async () => {
      if (isIosNativeOnly()) {
        await StatusBar.setStyle({ style: Style.Light });
      }
    });
    this.lightbox = lightbox;
  },
  methods: {
    isPhotoSelected(photo) {
      return this.selectedPhotos.includes(photo);
    },
    formatDate(date) {
      momentts.tz.setDefault("Asia/Singapore");
      const dateObject = momentts(date);
      return dateObject.format("dddd, D MMMM YYYY");
    },
    formatExpType(expType) {
      return this.expData[expType].title;
    },
    formatPhoto(photos) {
      const arr = photos;
      return arr.map((photos) => {
        return {
          src: photos,
          thumbnail: photos,
          w: 600,
          h: 600,
        };
      });
    },
    selectAllInGallery() {
      const previousState = this.isAllPhotosSelected;
      this.links.forEach((p, i) => {
        if (!previousState) {
          this.$store.dispatch("photos/addSelectedPhoto", p);
        } else {
          this.selectPhotos(p, i);
        }
      });
    },
    async selectPhotos(photo, index) {
      if (this.isSelectingPhotos) {
        await this.$store.dispatch("photos/toggleSelectedPhoto", photo);
      } else {
        this.lightbox.loadAndOpen(index, {
          gallery: document.querySelector("#" + this.galleryId),
        });
      }
    },
    outsideClick(event) {
      const { tooltip, tooltipTrigger } = this.$refs;

      if (!tooltip.contains(event.target) && event.target !== tooltipTrigger) {
        this.showingTooltip = false;
      }
    },
    toggleTooltip() {
      this.showingTooltip = !this.showingTooltip;
    },
  },
};
</script>

<style scoped>
.tiles {
  grid-template-columns: repeat(auto-fill, minmax(106px, 0));
}

@media (min-width: 768px) {
  .tiles {
    grid-template-columns: repeat(auto-fill, minmax(200px, 0));
  }
}

.tooltip-box {
  top: calc(100% + 14px);
  right: -10px;

  box-shadow: 0px 0px 5px rgba(11, 12, 15, 0.35);
  /* filter: drop-shadow(0px 0px 5px rgba(11, 12, 15, 0.35)); */
}

.tooltip-box:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid #f8f9f9;
  border-color: transparent #f8f9f9 #f8f9f9 transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: -3px -3px 3px rgba(11, 12, 15, 0.15);
}
.tooltip-box:after {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid white;
  border-color: #f8f9f9 transparent transparent #f8f9f9;
  transform-origin: 0 0;
  transform: rotate(45deg);
  z-index: 2;
}
</style>
