<template>
  <div class="bg-white pb-[48px]">
    <div
      class="schedule-section bg-repeat-x xs:mx-6 md:mx-8 lg:w-[750px] rounded-3xl lg:mx-auto mt-4"
      :class="bgClass"
    >
      <section
        class="pt-8 pb-5 flex flex-col justify-center items-center font-aktiv-grotesk font-bold text-[20px] leading-[24px]"
      >
        <div v-if="type === 'GC' || (type === 'SCS' && !isWeekday)">
          <img
            id="gens-conf-logo"
            src="../../../public/assets/images/gpc-2024-logo.png"
            class="w-[182px] mx-auto pb-2"
          />
        </div>
        <div v-else>
          <img
            id="scs-logo"
            src="../../../public/assets/images/gpc-2024-logo.png"
            class="w-[278px] mx-auto pb-4"
          />
        </div>
      </section>
      <section
        class="flex flex-col space-y-6 pt-6 pb-[24px] rounded-3xl bg-grey-5"
      >
        <div v-for="(event, id) in eventsWithDate" :key="id">
          <div
            v-if="event.type === 'spe'"
            class="mb-[24px] mx-[24px] space-y-6"
          >
            <TimeSeparator
              :now="false"
              :show-time="true"
              start-time="2022-10-23T02:30:00.000Z"
              class="mb-[24px]"
            />

            <ServicePrepExperienceCard></ServicePrepExperienceCard>

            <CollabConversationCard v-if="type === 'SCS'" />
          </div>

          <EventsCard
            v-else
            :id="event.id"
            :event-type="event.type"
            :show-time="event.showTime"
            :label="event.label"
            :title="event.title"
            :location="event.location"
            :notes="event.notes"
            :sessionId="event.sessionId ? event.sessionId.sessionId : 0"
            :is-weekday="isWeekday"
            :now="event.now"
            :start-time="event.startTime"
            :has-seating="hasSeating(event)"
            :alumni="event.alumni"
            :toggle-alumni="true"
            :timer-txt="event.timerTxt"
            :timer-txt-replace="event.timerTxtReplace"
            :hide-delegate-type="event.hideDelegateType"
          />
        </div>
        <EodPanel
          v-if="reachEod()"
          :day="day"
          :is-past-last-day="true"
        ></EodPanel>
        <div
          class="flex flex-col justify-center mt-6 mb-10 font-aktiv-grotesk-trial text-[20px] leading-[24px] mx-auto"
        >
          <button
            v-if="day <= lastDay && !reachEod()"
            class="rounded-full grow-0 text-white text-center px-5 py-3"
            :class="btnClass"
            @click="goToSchedule()"
            data-test-id="gensapp-home-schedule-goToSchedule"
          >
            Go to Schedule
          </button>
          <button
            v-else-if="day < lastDay && reachEod()"
            class="rounded-full grow-0 text-white text-center px-5 py-3"
            :class="btnClass"
            @click="goToNextSchedule()"
            data-test-id="gensapp-home-schedule-goToNextSchedule"
          >
            View next day’s schedule
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import moment from "moment";
import momentts from "moment-timezone";
import axios from "axios";
import TimeSeparator from "../SchedulePage/TimeSeparator.vue";
import CollabConversationCard from "../SchedulePage/CollabConversationCard.vue";
import ServicePrepExperienceCard from "../SchedulePage/ServicePrepExperienceCard.vue";
import EventsCard from "../SchedulePage/EventsCard.vue";
import EodPanel from "./EodPanel.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "ScheduleSection",
  components: {
    EventsCard,
    EodPanel,
    ServicePrepExperienceCard,
    CollabConversationCard,
    TimeSeparator,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    seatings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTime: null,
      currentDate: "",
      currentEvents: [],
      inBetweenEvents: [],
      nextEvents: [],
      schedules: {
        "2024-07-11": {
          day: 1,
          weekday: true,
          beforeEvent: null,
          afterEvent: null,
        },
        "2024-07-12": {
          day: 2,
          weekday: true,
          beforeEvent: null,
          afterEvent: null,
        },
        "2024-07-13": {
          day: 3,
          weekday: false,
          beforeEvent: null,
          afterEvent: null,
        },
        "2024-07-14": {
          day: 4,
          weekday: false,
          beforeEvent: null,
          afterEvent: null,
        },
        "2024-07-15": {
          day: 5,
          weekday: true,
          beforeEvent: null,
          afterEvent: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    ...mapGetters("timer", ["currDate"]),
    ...mapState("timer", ["speed"]),
    ...mapState("user", ["token"]),
    scheduleLink() {
      return this.type === "SCS"
        ? "https://gens.page/2024schedule"
        : "https://gens.page/2023gcschedule";
    },
    eventMaps() {
      if (
        !this.type ||
        !this.closestDateStr ||
        !(this.closestDateStr in this.schedules) ||
        this.events.length === 0
      )
        return {};

      if (this.type === "GC" && this.schedules[this.closestDateStr].weekday)
        return {};

      const map = {};
      if (this.schedules[this.closestDateStr].beforeEvent) {
        // before event creation
        const eventDate = new Date(
          this.schedules[this.closestDateStr].beforeEvent.startTime
        );
        const beforeEventTs = eventDate.getTime();
        if (!(beforeEventTs in map)) {
          map[beforeEventTs] = [];
        }

        const beforeEvent = {
          id: -1,
          startTime: this.schedules[this.closestDateStr].beforeEvent.startTime,
          type: this.schedules[this.closestDateStr].beforeEvent.type,
          durationInMin:
            this.schedules[this.closestDateStr].beforeEvent.durationInMin,
        };
        map[beforeEventTs].push(beforeEvent);
      }

      this.events.forEach((event) => {
        const eventTs = new Date(event.startTime);

        if (!(eventTs.getTime() in map)) {
          map[eventTs.getTime()] = [];
        }
        map[eventTs.getTime()].push(event);
      });

      // after event creation
      if (this.schedules[this.closestDateStr].afterEvent) {
        const eventDate = new Date(
          this.schedules[this.closestDateStr].afterEvent.startTime
        );
        const afterEventTs = eventDate.getTime();
        if (!(afterEventTs in map)) {
          map[afterEventTs] = [];
        }

        const afterEvent = {
          id: -2,
          startTime: this.schedules[this.closestDateStr].afterEvent.startTime,
          type: this.schedules[this.closestDateStr].afterEvent.type,
          durationInMin:
            this.schedules[this.closestDateStr].afterEvent.durationInMin,
        };
        map[afterEventTs].push(afterEvent);
      }
      return map;
    },
    closestDateStr() {
      momentts.tz.setDefault("Asia/Singapore");
      let displayStr =
        this.currDate === ""
          ? momentts().format("YYYY-MM-DD")
          : momentts(this.currDate).format("YYYY-MM-DD");
      const dateFirstStr = "2024-07-11";
      const dateLastStr = "2024-07-15";
      const dateFirstStrGC = "2024-07-13";
      const dateLastStrGC = "2024-07-14";

      const display = new Date(displayStr);
      const dateFirst = new Date(dateFirstStr);
      const dateLast = new Date(dateLastStr);
      const dateFirstGC = new Date(dateFirstStrGC);
      const dateLastGC = new Date(dateLastStrGC);

      if (display.getTime() < dateFirst.getTime()) displayStr = dateFirstStr;
      if (display.getTime() > dateLast.getTime()) displayStr = dateLastStr;
      if (display.getTime() < dateFirstGC.getTime() && this.type === "GC")
        displayStr = dateFirstStrGC;
      if (display.getTime() > dateLastGC.getTime() && this.type === "GC")
        displayStr = dateLastStrGC;
      return displayStr;
    },
    eventsWithDate() {
      const computedEvent = [];
      if (this.currentEvents.length) {
        let idx = 0;
        this.currentEvents.forEach((event) => {
          if (idx === 0) event.showTime = true;
          else event.showTime = false;
          computedEvent.push(event);
          idx++;
        });
      }
      if (this.inBetweenEvents.length) {
        let idx = 0;
        this.inBetweenEvents.forEach((event) => {
          if (idx === 0) event.showTime = true;
          else event.showTime = false;
          computedEvent.push(event);
          idx++;
        });
      }
      if (this.nextEvents.length) {
        let idx = 0;
        this.nextEvents.forEach((event) => {
          if (idx === 0) event.showTime = true;
          else event.showTime = false;
          computedEvent.push(event);
          idx++;
        });
      }
      return computedEvent;
    },
    day() {
      if (!this.currentTime) return 0;

      momentts.tz.setDefault("Asia/Singapore");
      const earliestDate =
        this.type === "SCS"
          ? momentts("2024-07-11T00:00:00+08")
          : momentts("2024-07-13T00:00:00+08");
      const latestDate =
        this.type === "SCS"
          ? momentts("2024-07-15T23:59:59+08")
          : momentts("2024-07-14T23:59:59+08");
      if (this.currentTime.getTime() < earliestDate.unix() * 1000) return 0;
      if (this.currentTime.getTime() > latestDate.unix() * 1000)
        return this.type === "SCS" ? 6 : 3;
      return this.type === "SCS"
        ? this.schedules[this.currentDate].day
        : this.schedules[this.currentDate].day - 2;
    },
    isWeekday() {
      if (!this.currentDate || !(this.currentDate in this.schedules))
        return true;

      return this.schedules[this.currentDate].weekday;
    },
    bgClass() {
      return this.type === "SCS" && this.isWeekday
        ? "bg-gens-conf-home"
        : "bg-gens-conf-home";
    },
    btnClass() {
      return this.type === "SCS" && this.isWeekday ? "bg-blue" : "bg-blue";
    },
    btnOutlineClass() {
      return this.type === "SCS" && this.isWeekday
        ? "border-blue "
        : "border-blue";
    },
    lastDay() {
      return this.type === "SCS" ? 5 : 2;
    },
    seatingMap() {
      if (!this.seatings) return {};
      const map = {};
      this.seatings.forEach((seating) => {
        map[seating.serviceId] = seating.allocation !== "";
      });
      return map;
    },
  },
  watch: {
    currentTime(newTime, oldTime) {
      const oldCurrDate = this.currentDate;
      momentts.tz.setDefault("Asia/Singapore");
      this.currentDate = momentts(this.currentTime).format("YYYY-MM-DD");

      if (this.currentDate !== oldCurrDate)
        this.$emit("init", this.currentDate);

      if (!this.nextEvents.length && !this.currentEvents.length) return;

      const afterEvents = this.inBetweenEvents.length
        ? this.inBetweenEvents
        : this.nextEvents;

      if (afterEvents.length) {
        const nextEventDate = new Date(afterEvents[0].startTime);
        if (newTime.getTime() < nextEventDate.getTime()) return;

        this.setClosestEvent();
      } else {
        const currEventDate = new Date(this.currentEvents[0].startTime);
        const durationInMin = this.currentEvents[0].durationInMin;

        if (
          newTime.getTime() >=
          currEventDate.getTime() + durationInMin * 60 * 1000
        )
          this.setClosestEvent();
      }
    },
    eventMaps(newMaps, oldMap) {
      this.setClosestEvent();
    },
    currDate(newDate, oldDate) {
      this.currentTime = new Date(newDate);
    },
  },
  async mounted() {
    momentts.tz.setDefault("Asia/Singapore");
    this.currentTime = this.currDate ? new Date(this.currDate) : new Date();
    this.currentDate = momentts(this.currentTime).format("YYYY-MM-DD");
    this.timer();
    this.setClosestEvent();
  },
  beforeDestroy() {
    clearInterval(this.intervalid);
  },
  methods: {
    ...mapActions("schedule", ["setLinkOpenDate"]),
    timer() {
      this.intervalid = setInterval(() => {
        this.currentTime = new Date(
          this.currentTime.getTime() + 1000 * this.speed
        );
      }, 1000);
    },
    setNowEvents(events) {
      const nowEvents = [];
      events.forEach((event) => {
        event.now = true;
        nowEvents.push(event);
      });
      return nowEvents;
    },
    setClosestEvent() {
      this.currentEvents = [];
      this.nextEvents = [];
      this.inBetweenEvents = [];

      const dateTsArray = Object.keys(this.eventMaps);
      if (!dateTsArray.length) return;

      dateTsArray.sort();
      dateTsArray.every((dateTs) => {
        const events = [...this.eventMaps[dateTs]];

        if (this.currentTime.getTime() >= dateTs && events[0].type === "end") {
          this.currentEvents = [];
          this.inBetweenEvents = [];
          this.nextEvents = [];
          return false;
        }

        if (
          this.currentTime.getTime() >= dateTs &&
          this.currentTime.getTime() <
            parseInt(dateTs) + events[0].durationInMin * 60 * 1000 &&
          events[0].type !== "break"
        ) {
          this.currentEvents = this.setNowEvents(events);
          this.inBetweenEvents = [];
        }

        if (events[0].type === "break") {
          this.inBetweenEvents = events;
          if (this.currentTime.getTime() >= dateTs) {
            this.currentEvents = [];
            this.inBetweenEvents = [];
          }
        }

        if (
          !this.nextEvents.length &&
          this.currentTime.getTime() < dateTs &&
          events[0].type !== "break"
        ) {
          this.nextEvents = events;
          // check the difference of timing to determine the text
          if (events.length) {
            const days = this.getDifferenceDay(
              this.currentTime.getTime(),
              dateTs
            );
            if (days == 1) {
              events[0].timerTxt = "Tomorrow, ";
              events[0].timerTxtReplace = false;
            } else if (days > 1) {
              events[0].timerTxt = "Coming Soon";
              events[0].timerTxtReplace = true;
            }
          }

          return false;
        }

        return true;
      });

      // clean up break session
      if (!this.nextEvents.length || !this.currentEvents.length) {
        this.inBetweenEvents = [];
      }
    },
    goToSchedule() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "go to schedule",
          page: "home",
        },
      });

      if (this.currentDate in this.schedules)
        this.setLinkOpenDate(this.currentDate);
      this.$router.push("/schedule");
    },
    goToNextSchedule() {
      momentts.tz.setDefault("Asia/Singapore");
      const nextDate = momentts(this.currentDate)
        .add(86400000)
        .format("YYYY-MM-DD");

      if (nextDate in this.schedules) this.setLinkOpenDate(nextDate);
      this.$router.push("/schedule");
    },
    reachEod() {
      if (!this.eventsWithDate.length) return true;

      const filteredEvents = this.eventsWithDate.filter((event) => {
        return event.type === "end";
      });
      return filteredEvents.length > 0;
    },
    hasSeating(event) {
      const serviceId = this.getServiceId(event);
      if (serviceId === null) return false;

      return serviceId in this.seatingMap && this.seatingMap[serviceId];
    },
    getServiceId(event) {
      if (!event.title || !event.label) return null;
      // no the best way to handle, should use type instead of string manipulation since copy can change
      if (event.title.includes("Leaders' Meeting")) return 0;
      if (event.label.includes("SERVICE")) {
        const labelList = event.label.split(" ");
        return labelList[1];
      }
      return null;
    },
    getDifferenceDay(previousDateTs, nextDateTs) {
      momentts.tz.setDefault("Asia/Singapore");
      const previousDateObj = momentts(previousDateTs);
      const nextDateObj = momentts(parseInt(nextDateTs));
      const previousStartofDay = previousDateObj.startOf("day");
      const previousNextofDay = nextDateObj.startOf("day");
      const durationDiff = previousNextofDay.diff(
        previousStartofDay,
        "day",
        true
      );
      return durationDiff;
    },
  },
};
</script>

<style scoped></style>
