// import { set } from 'vue'
import axios from "axios";

const INITIAL_STATE = {
  signups: { CG: null, DSN: null, PM: null, MD: null },
  invitedMcrSignups: {},
  pending_signups: [
    {
      type: "CG",
      name: "Connect Group Experience",
    },
    {
      type: "DSN",
      name: "Dreams & Stories Night",
    },
    {
      type: "PM",
      name: "Prayer Meeting Experience",
    },
    {
      type: "MD",
      name: "Debrief Experience",
    },
  ],
  SIGN_UP_CLOSE_TS: "",
  EXP_EVENT_END_TS: "",
  EXP_ALLOC_TS: "",
  loading: false,
  backFromSummary: false,
  successFromSummary: false,
  firstSignUp: true,
  expData: null,
  mainExpData: null,
};

const state = () => INITIAL_STATE;

const mutations = {
  setSignupState(state, newSignups) {
    const signups = { ...state.signups };

    const invitedMcrSignups = {};
    newSignups.forEach((signup) => {
      if (signup.type !== "MCR") signups[signup.type] = signup;
      else {
        invitedMcrSignups[signup.scheduleId] = signup;
      }
    });
    state.invitedMcrSignups = invitedMcrSignups;
    state.signups = signups;
  },
  setSignupDates(state, payload) {
    state[payload.name] = payload.value;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  removeSignupState(state, type) {
    const signups = { ...state.signups };
    delete signups[type];
    state.signups = signups;
  },
  setPendingSignupState(state, pendingSignups) {
    state.pending_signups = pendingSignups;
  },
  setBackFromSummary(state, backFromSummary) {
    state.backFromSummary = backFromSummary;
  },
  setSuccessFromSummary(state, successFromSummary) {
    state.successFromSummary = successFromSummary;
  },
  setFirstSignUpState(state, firstSignUp) {
    state.firstSignUp = firstSignUp;
  },

  setPendingSignupBySignupState(state, data) {
    if (!data.length) return;

    // no need to sort is sorted in db
    const expTypeList = data.map(({ type }) => type);

    const pendingSignupsMap = state.pending_signups.reduce((map, obj) => {
      map[obj.type] = obj;
      return map;
    }, {});

    const finalExpList = [];
    expTypeList.forEach((type) => {
      if (type !== "MCR") {
        finalExpList.push(pendingSignupsMap[type]);
      }
    });
    if (finalExpList.length != 0) {
      state.firstSignUp = false;
    }
    state.pending_signups = finalExpList;
  },
  setExpData(state, expData) {
    state.expData = expData;
  },
  setMainExpData(state, mainExpData) {
    state.mainExpData = mainExpData;
  },
};

const actions = {
  async fetchSignup({ dispatch, state }) {
    // Will not call backend api if profile already set
    // if (state.signups !== null) return
    console.log("in fetchSignup");
    await dispatch("forceFetchSignup");
  },
  async forceFetchSignup({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/exps/signup",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in setting signup status");
      console.log("in forcefetchSignup");

      commit("setSignupState", data);
      commit("setPendingSignupBySignupState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async fetchDates({ dispatch, state }) {
    // Will not call backend api if profile already set
    // if (state.signups !== null) return
    await dispatch("forceFetchDates");
  },

  async forceFetchDates({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const list = Object.keys(INITIAL_STATE).filter((key) =>
        key.includes("_TS")
      );
      for (const paramName of list) {
        const { status, data } = await axios.get(
          process.env.VUE_APP_API_URL + "/sysparams/params/" + paramName,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (status !== 200) throw new Error("Failed in setting signup dates");
        commit("setSignupDates", {
          name: paramName,
          value: data,
        });
      }
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async fetchExpData({ dispatch, state }) {
    // if (state.expData) return;
    await dispatch("forceFetchExpData");
  },

  async forceFetchExpData({ commit, dispatch, rootState }) {
    const accessToken = rootState.user.token;
    const { status, data } = await axios.get(
      process.env.VUE_APP_API_URL + "/cms/experiences/get",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (status !== 200) throw new Error("Failed in fetching exp dates");
    // Changing keys of data to match exp type
    var new_data = {};
    Object.keys(data).forEach((key) => {
      const exp_type = data[key].type;
      new_data[exp_type] = data[key];
    });
    commit("setExpData", new_data);
  },

  async fetchMainExpData({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/mainExp/get",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in fetching main exp data");
      commit("setMainExpData", data);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch main exp data.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setSignupState(context, payload) {
    await context.commit("setSignupState", payload);
  },
  async removeSignupState(context, type) {
    await context.commit("removeSignupState", type);
  },
  async setPendingSignupState(context, pendingSignups) {
    await context.commit("setPendingSignupState", pendingSignups);
  },
  async setBackFromSummary(context, backFromSummary) {
    await context.commit("setBackFromSummary", backFromSummary);
  },
  async setSuccessFromSummary(context, successFromSummary) {
    await context.commit("setSuccessFromSummary", successFromSummary);
  },
  async setFirstSignUpState(context, firstSignUp) {
    await context.commit("setFirstSignUpState", firstSignUp);
  },
};

const getters = {
  cg(state) {
    if (!state.signups || !("CG" in state.signups)) return null;
    return state.signups.CG;
  },
  spe(state) {
    if (!state.signups || !("SPE" in state.signups)) return null;
    return state.signups.SPE;
  },
  md(state) {
    if (!state.signups || !("MD" in state.signups)) return null;
    return state.signups.MD;
  },
  SIGN_UP_CLOSE_TS(state) {
    return state.SIGN_UP_CLOSE_TS;
  },
  EXP_EVENT_END_TS(state) {
    return state.EXP_EVENT_END_TS;
  },
  EXP_ALLOC_TS(state) {
    return state.EXP_ALLOC_TS;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
