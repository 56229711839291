<template>
  <ion-page>
    <ion-header>
      <!-- <ion-toolbar>topbar</ion-toolbar> -->
    </ion-header>
    <NotificationPopup />
    <ion-content
      :id="id"
      fullscreen="false"
      class="master"
      :scroll-events="enableScrollEvent"
      @ionScroll="onScroll"
    >
      <ExperienceModal
        ref="exp-modal"
        v-if="selectedExp"
        :show="showExpModal"
        @close-modal="toggleExpModal"
        :exp="selectedExp"
        :show-button="showExpButton"
      ></ExperienceModal>
      <div
        v-if="showFilterModal"
        class="z-[99] w-[100vw] h-[calc(--app-height)] fixed inset-0 bg-[#0B0C0F99] bg-opacity-50 flex items-center md:justify-center"
      >
        <div
          v-if="showFilterModal"
          class="z-[99] w-[100vw] h-[calc(--app-height)] lg:w-[400px] lg:h-[600px] fixed lg:static inset-0"
        >
          <FilterModal />
        </div>
      </div>
      <LogoutPopup
        :show="showLogoutPopup"
        @close-modal="toggleLogoutPopup()"
      ></LogoutPopup>
      <PhotoDownloadModal
        :show="showPhotoPopup"
        @close-modal="togglePhotoPopup()"
      ></PhotoDownloadModal>
      <NewsletterModal
        :show="showNewsletterPopup"
        title="Share with your team"
        description="Fill in their name and email. <br>
        We will send each of them an invite to the GenS App!"
        :show-name="true"
        subscribe="Send email invite"
        @close-modal="toggleNewsletterPopup()"
      ></NewsletterModal>

      <Header v-if="showHeader" @open-modal="toggleLogoutPopup()" />
      <main class="lg:flex h-full lg:h-auto">
        <SideBar class="shrink-0" @open-modal="toggleLogoutPopup()" />
        <slot
          class="flex-1 pb-16 lg:pb-0"
          test="test"
          :toggleExp="toggleExpModal"
          :togglePhoto="togglePhotoPopup"
          :toggleNewsletter="toggleNewsletterPopup"
          :closeExp="closeExpModal"
        >
        </slot>
      </main>
    </ion-content>
    <ion-footer>
      <ion-toolbar
        class="bg-white h-[68px] lg:hidden box-shadow"
        :class="{ 'h-[85px]': isIos() }"
      >
        <BottomNav />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  isPlatform,
} from "@ionic/vue";
import { StatusBar } from "@capacitor/status-bar";
import BottomNav from "@/components/common/BottomNav";
import SideBar from "@/components/common/SideBar";
import Header from "../components/common/Header.vue";
import LogoutPopup from "../components/HomePage/LogoutPopup.vue";
import NotificationPopup from "@/components/common/NotificationPopup.vue";
import ExperienceModal from "../components/ExperiencePage/ExperienceModal.vue";
import PhotoDownloadModal from "../components/Photo/PhotoDownloadModal.vue";
import NewsletterModal from "@/components/ResourcesPage/NewsletterModal.vue";
import store from "@/store";
import { isIosNativeOnly } from "@/utils/platform-check";
import { mapState } from "vuex";
import FilterModal from "@/components/ResourcesPage/FilterModal.vue";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    BottomNav,
    SideBar,
    Header,
    LogoutPopup,
    NotificationPopup,
    ExperienceModal,
    PhotoDownloadModal,
    FilterModal,
    NewsletterModal,
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    enableScrollEvent: {
      type: Boolean,
      default: false,
    },
    noLogin: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "ion-content",
    },
  },
  data() {
    return {
      showLogoutPopup: false,
      showPhotoPopup: false,
      showNewsletterPopup: false,
      showExpModal: false,
      selectedExp: null,
      showExpButton: false,
    };
  },
  computed: {
    ...mapState("filter", ["showFilterModal"]),
    showHeader() {
      return !this.$route.path.includes("home");
    },
  },
  async mounted() {
    if (!this.noLogin) {
      // await StatusBar.setOverlaysWebView({ overlay: true });
      await store.dispatch("profile/fetchProfile");
    }
  },
  async IonViewWillEnter() {
    if (isPlatform("android") && !isPlatform("mobileweb")) {
      // Display content under transparent status bar (Android only)
      await StatusBar.setOverlaysWebView({ overlay: true });
    }
  },
  methods: {
    toggleLogoutPopup() {
      this.showLogoutPopup = !this.showLogoutPopup;
    },
    togglePhotoPopup() {
      this.showPhotoPopup = !this.showPhotoPopup;
    },
    toggleNewsletterPopup() {
      this.showNewsletterPopup = !this.showNewsletterPopup;
    },
    closeExpModal() {
      this.showExpModal = false;
    },
    toggleExpModal(exp, showButton) {
      this.showExpButton = showButton;
      this.showExpModal = !this.showExpModal;
      this.selectedExp = exp;
      this.$nextTick(() => {
        if (this.$refs["exp-modal"]) {
          this.$refs["exp-modal"].update();
        }
      });
    },
    onScroll(event) {
      this.$emit("on-scroll", event);
    },
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>
<style scoped>
ion-toolbar {
  --border-color: transparent;
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-content.master {
  --padding-bottom: 0px;
}

.footer-md::before {
  display: none;
}

/* ion-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
} */

.box-shadow {
  box-shadow: 0px 1px 25px 0px #0000001a;
}
</style>
