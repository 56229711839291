<template>
  <div
    class="flex flex-col text-center mx-auto w-[303px] md:w-[80%] font-aktiv-grotesk-regular text-[20px] leading-[24px] text-grey-80"
  >
    <h1 v-if="day >= 1 && day <= lastDay" class="font-aktiv-grotesk-trial pb-2">
      End of Day {{ day }}
    </h1>
    <h1 v-else-if="day > lastDay" class="font-aktiv-grotesk-trial pb-2">
      Till next time!
    </h1>

    <p v-if="day < 1" class="font-aktiv-grotesk-trial">
      Hello! We can’t wait to see you soon!
    </p>
    <p v-else-if="day === lastDay">
      See you in 2025!<br />
      <in-app-browser
        v-if="isPastLastDay"
        href="https://generationsmvmt.com/conference/generations-pastors-conference/"
        target="_blank"
        class="underline font-aktiv-grotesk-trial cursor-pointer"
        :class="getTextColor()"
        data-event-tracking="gensapp-schedule-day-5-2025apply"
        data-test-id="gensapp-schedule-2024conf-register-signupnow"
        @click="logEvent()"
        >Apply Now</in-app-browser
      >
    </p>
    <div v-else-if="day > lastDay">
      <p class="pb-6">
        Want to review the session resources? <br />
        Head to the
        <router-link
          to="/resources/sessions"
          class="underline font-aktiv-grotesk-trial"
          :class="getTextColor()"
          >Resources Page</router-link
        >.
      </p>

      <p class="w-[290px] md:w-auto">
        We loved having you with us! See you soon! 🤩
      </p>
    </div>
    <p v-if="day === 1 && isSCS" class="font-aktiv-grotesk-regular">
      Spirits energised. Hearts full. Gonna be a great 5 days!
    </p>
    <p v-if="day === 2 && isSCS" class="font-aktiv-grotesk-regular">
      What an incredible two days. Time to recharge for the weekend!
    </p>
    <p v-if="day === 3" class="font-aktiv-grotesk-regular">
      Rest up, we’ve got more to come. See you tomorrow!
    </p>
    <p v-if="day === 1 && !isSCS" class="font-aktiv-grotesk-regular">
      Rest up, we’ve got more to come. See you tomorrow!
    </p>
    <p v-if="day === 4" class="font-aktiv-grotesk-regular">
      What a weekend it’s been! But we’re not done. See you tomorrow!
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "EodPanel",
  props: {
    day: {
      type: Number,
      default: 0,
    },
    isPastLastDay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    ...mapState("timer", ["currDate"]),
    eventName() {
      return this.type === "SCS"
        ? "Strong Church Summit"
        : "GenerationS Conference";
    },
    lastDay() {
      return this.type === "SCS" ? 5 : 2;
    },
    isSCS() {
      return this.type === "SCS";
    },
  },
  methods: {
    getTextColor() {
      return this.type === "SCS" ? "text-lightblue" : "text-lightblue";
    },
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-schedule-day-5-2025apply",
          page: "schedule",
        },
      });
    },
  },
};
</script>

<style scoped></style>
