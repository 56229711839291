<template>
  <aside
    class="hidden min-h-[calc(100vh-88px)] min-w-[292px] lg:block"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
  >
    <div
      :class="{
        'top-0': !showHeader,
        'top-navbar-height': showHeader,
      }"
      class="hidden fixed bottom-0 left-0 w-[292px] flex-col px-[24px] pb-[40px] lg:flex bg-grey-5 overflow-y-auto no-scrollbar z-30"
      style="box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1)"
    >
      <section
        v-if="isIndexPage()"
        class="pt-[72px] flex flex-row justify-between items-center"
      >
        <div
          class="rounded-full w-[56px] h-[56px] bg-lightblue border-white border-[2px] relative"
        >
          <div
            v-if="isDropdownVisible"
            class="bg-black absolute rounded-full w-[51px] h-[51px] opacity-30"
          ></div>
          <button
            v-if="showProfilePic"
            class="side-profile-icon"
            @click="toggleDropdown"
          >
            <img :src="profilePicture" class="rounded-full" />
          </button>
          <button
            v-if="!showProfilePic"
            class="side-profile-icon text-[#FFF] text-[28px] w-[56px] h-[56px] font-family-['aktiv-grotesk-medium'] text-center leading-loose"
            @click="toggleDropdown"
          >
            {{ myName.charAt(0) }}
          </button>
          <!-- <div
            class="rounded-full w-[20px] h-[20px] left-[40px] top-[38px] bg-orange absolute dropdown"
          >
            <button @click="toggleDropdown">
              <img
                src="../../../public/assets/icons/iconamoon_arrow-up-2-light.svg"
              />
            </button>
          </div> -->
          <div
            v-if="isDropdownVisible"
            v-click-away="removeDropdown"
            class="w-[144px] mt-[16px] relative py-[15px] bg-white rounded-[12px] inline-flex flex-col items-start shadow-lg"
          >
            <router-link to="/profile">
              <div
                class="flex px-[12px] h-[20px] items-center gap-[8px] self-stretch text-grey-100 text-[14px] font-[400] leading-[20px]"
                data-event-tracking="gensapp-profile-viewprofile"
                @click="logEvent('gensapp-profile-viewprofile')"
              >
                <img
                  src="../../../public/assets/icons/profile-circle.svg"
                  data-event-tracking="gensapp-profile-viewprofile"
                  @click="logEvent('gensapp-profile-viewprofile')"
                />
                <div
                  data-event-tracking="gensapp-profile-viewprofile"
                  @click="logEvent('gensapp-profile-viewprofile')"
                >
                  Profile
                </div>
              </div>
            </router-link>
            <div
              class="flex px-[12px] h-[20px] items-center gap-[8px] mt-[12px] self-stretch text-[#B80A0A] text-[14px] font-[400] leading-[20px] cursor-pointer"
              data-event-tracking="gensapp-home-logout-text"
              data-test-id="gensapp-home-logout-text"
              @click="openModal()"
            >
              <img src="../../../public/assets/icons/log-out-black.svg" />Log
              Out
            </div>
          </div>
        </div>

        <div
          class="w-[176px] text-[20px] ml-3 leading-[24px] font-aktiv-grotesk-trial"
        >
          {{ myName }}
        </div>
      </section>
      <section :class="getMarginTop()">
        <OutlineButton
          v-for="(link, i) in announcementIconSwap()"
          :key="i"
          :icon="link.icon"
          :to="link.to"
          :label="link.label"
          :is-external="link.isExternal"
          :data-event-tracking="link.dataEventTracking"
          :data-test-id="link.dataTestId"
          class="mb-[12px]"
          @clicked-button="clearSchedule()"
        />
      </section>

      <section class="mt-auto flex flex-col justify-center items-center">
        <div class="flex-container space-x-3">
          <a
            class="text-[14px] leading-[20px] text-grey-60 hover:underline"
            href="/terms-of-use"
            target="_blank"
            data-event-tracking="gensapp-menu-termsofuse"
            data-test-id="gensapp-menu-termsofuse"
            >Terms of Use</a
          >
          <a
            class="text-[14px] leading-[20px] text-grey-60 hover:underline"
            href="/privacy-policy"
            target="_blank"
            data-event-tracking="gensapp-menu-privacypolicy"
            data-test-id="gensapp-menu-privacypolicy"
            >Privacy Policy</a
          >
        </div>
      </section>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import OutlineButton from "./OutlineButton.vue";
import OutlineHomeIcon from "@/components/icons/OutlineHome";
import OutlineScheduleIcon from "@/components/icons/OutlineSchedule";
import OutlineTeamIcon from "@/components/icons/OutlineTeam";
import OutlineResourcesIcon from "@/components/icons/OutlineResources";
import OutlineAnnouncementIcon from "@/components/icons/OutlineAnnouncement";
import OutlineUnreadAnnouncementIcon from "@/components/icons/OutlineUnreadAnnouncement";
import { mixin as clickaway } from "vue3-click-away";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  mixins: [clickaway],
  components: {
    OutlineButton,
  },
  data() {
    return {
      links: [
        {
          to: "/home",
          label: "Home",
          icon: OutlineHomeIcon,
          dataEventTracking: "gensapp-menu-home",
          dataTestId: "gensapp-sidebar-menu-home",
        },
        {
          to: "/resources/sessions",
          label: "Resources",
          icon: OutlineResourcesIcon,
          dataEventTracking: "gensapp-menu-resources",
          dataTestId: "gensapp-sidebar-menu-resources",
        },
        {
          to: "/schedule",
          label: "Schedule",
          icon: OutlineScheduleIcon,
          dataEventTracking: "gensapp-menu-schedule",
          dataTestId: "gensapp-sidebar-menu-schedule",
        },
        {
          to: "/team",
          label: "Team",
          icon: OutlineTeamIcon,
          dataEventTracking: "gensapp-menu-team",
          dataTestId: "gensapp-sidebar-menu-team",
        },
        {
          to: "/announcement",
          label: "Updates",
          icon: OutlineAnnouncementIcon,
          dataEventTracking: "gensapp-menu-announcement",
          dataTestId: "gensapp-sidebar-menu-announcement",
        },
      ],
      isDropdownVisible: false,
    };
  },
  computed: {
    ...mapGetters("profile", ["name"]),
    ...mapState("announcements", ["unreadCount"]),
    ...mapState("social", ["profilePicture"]),
    ...mapState("social", {
      displayName: "name",
    }),
    showHeader() {
      return this.$route.path !== "/home";
    },
    myName() {
      return this.displayName ? this.displayName : this.name;
    },
    showProfilePic() {
      return this.profilePicture;
    },
  },
  // async mounted() {
  //   await this.$store.dispatch("social/fetchSocialProfile");
  // },
  methods: {
    ...mapActions("schedule", ["setLinkOpenDate", "setScrollPosition"]),
    openModal() {
      this.isDropdownVisible = false;
      this.$emit("open-modal");
    },
    getMarginTop() {
      if (this.isIndexPage()) return "mt-4";
      return "mt-10";
    },
    isIndexPage() {
      return this.$route.path === "/home";
    },
    clearSchedule() {
      this.setLinkOpenDate("");
      this.setScrollPosition(1);
    },
    announcementIconSwap() {
      if (this.unreadCount > 0) {
        this.links[4].icon = OutlineUnreadAnnouncementIcon;
      } else {
        this.links[4].icon = OutlineAnnouncementIcon;
      }
      return this.links;
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    removeDropdown() {
      this.isDropdownVisible = false;
    },
    logEvent(event) {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: event,
          page: "home",
        },
      });
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.show {
  display: block;
}
</style>
