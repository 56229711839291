<template>
  <nav
    class="z-10 flex bg-white justify-evenly w-screen bottom-0 left-0 shadow lg:hidden py-[10px]"
    :class="{ 'pb-[20px]': isIos() }"
  >
    <BottomNavButton
      v-for="(button, i) in announcementIconSwap()"
      :key="i"
      :class="navButtonWidth"
      :label="button.label"
      :to="button.to"
      :icon="button.icon"
      :data-event-tracking="button.dataEventTracking"
      :data-test-id="button.dataTestId"
      @clicked-nav-button="clearSchedule()"
    />
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BottomNavButton from "./BottomNavButton.vue";
import { isIosNativeOnly } from "@/utils/platform-check";
import HomeIcon from "@/components/icons/Home";
import AnnouncementIcon from "@/components/icons/AnnouncementIcon";
import AnnouncementUnreadIcon from "@/components/icons/AnnouncementUnreadIcon";
import ScheduleIcon from "@/components/icons/Schedule";
import ResourcesIcon from "@/components/icons/Resources";
import TeamIcon from "@/components/icons/Team";

export default {
  components: { BottomNavButton },
  data() {
    return {
      buttons: [
        {
          to: "/home",
          icon: HomeIcon,
          label: "Home",
          dataEventTracking: "gensapp-menu-home",
          dataTestId: "gensapp-menu-home",
        },
        {
          to: "/resources/sessions",
          icon: ResourcesIcon,
          label: "Resources",
          dataEventTracking: "gensapp-menu-resources",
          dataTestId: "gensapp-menu-resources",
        },
        {
          to: "/schedule",
          icon: ScheduleIcon,
          label: "Schedule",
          dataEventTracking: "gensapp-menu-schedule",
          dataTestId: "gensapp-menu-schedule",
        },
        {
          to: "/team",
          icon: TeamIcon,
          label: "Team",
          dataEventTracking: "gensapp-menu-team",
          dataTestId: "gensapp-menu-team",
        },
        {
          to: "/announcement",
          icon: AnnouncementIcon,
          label: "Updates",
          dataEventTracking: "gensapp-menu-announcement",
          dataTestId: "gensapp-menu-announcement",
        },
      ],
    };
  },

  computed: {
    ...mapState("announcements", ["unreadCount"]),

    filteredButtons() {
      return this.buttons.filter();
    },
    navButtonWidth() {
      return "w-1/2";
    },
  },
  methods: {
    ...mapActions("schedule", ["setLinkOpenDate", "setScrollPosition"]),
    isIos() {
      return isIosNativeOnly();
    },
    clearSchedule() {
      console.log("clear schedule here...")
      this.setLinkOpenDate("");
      this.setScrollPosition(1);
    },
    announcementIconSwap() {
      if (this.unreadCount > 0) {
        this.buttons[4].icon = AnnouncementUnreadIcon;
      } else {
        this.buttons[4].icon = AnnouncementIcon;
      }
      return this.buttons;
    },
  },
};
</script>
<style scoped>
.shadow {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
}
</style>
