<template>
  <PopupModal
    :show="show"
    :enable-click-away="false"
    @close-popup="closeModal()"
  >
    <div
      id="mailer-modal"
      class="mailer-modal font-aktiv-grotesk"
      :data-event-tracking="'newsletter-window-' + computedDataEventTracking"
    >
      <div class="mailer-modal-content" :class="{ 'font-fc-minimal': isThai }">
        <img
          id="mailer-modal-close"
          src="../../../public/assets/icons/popup-close.svg"
          :data-event-tracking="'close-window-' + computedDataEventTracking"
          @click="closeModal"
        />
        <p
          class="mb-2 max-w-[250px] md:max-w-full font-aktiv-grotesk-medium"
          :class="{
            'mailer-modal-text-thai': isThai,
            'mailer-modal-text': !isThai,
          }"
        >
          {{ title }}
          <slot name="title" />
        </p>
        <p
          class="text-[#0B0C0F] mb-4 max-w-[300px] md:max-w-full"
          :class="{
            'font-aktiv-grotesk text-16-lh-28': !isThai,
            'font-fc-minimal text-20-lh-28 md:text-24-lh-28': isThai,
          }"
          v-html="description"
        ></p>
        <div
          class="mailer-button-container mb-4"
          :class="{
            hidden: !showName,
            'font-fc-minimal text-20-lh-24': isThai,
          }"
        >
          <input
            id="mailer-mobile-name-input"
            class="border-1 !border-grey-40"
            v-model="name"
            type="text"
            placeholder="Your friend's name"
            autocomplete="given-name"
            :data-event-tracking="'name-input-' + computedDataEventTracking"
            :disabled="computedFormDisabled"
          />
        </div>
        <div
          class="mailer-button-container"
          :class="{ 'font-fc-minimal text-20-lh-24': isThai }"
        >
          <input
            id="mailer-mobile-email-input"
            v-model="email"
            type="email"
            pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            placeholder="Your friend's email"
            :data-event-tracking="'email-input-' + computedDataEventTracking"
            :disabled="computedFormDisabled"
          />
        </div>
        <div id="mailer-modal-warning-container" :class="computedWarningStyle">
          <img
            src="https://d2duaskl0104ek.cloudfront.net/assets/books/images/warn-icon.svg"
          />
          <p :class="{ 'font-fc-minimal text-16-lh-20': isThai }">
            {{ errorMessage }}
          </p>
        </div>
        <p
          class="text-white max-w-[350px]"
          :class="{
            'mailer-modal-policy-text': !isThai,
            'mailer-modal-policy-text-thai': isThai,
          }"
        >
          <slot name="privacy" />
        </p>
        <button
          class="w-full text-white font-bold bg-[#00A9E0] rounded-[40px] pt-[12px] pr-[24px] pb-[12px] pl-[24px] md:w-auto"
          :class="{ '!bg-grey-30': computedFormDisabled }"
          :disabled="computedFormDisabled"
          :data-event-tracking="
            'newsletter-submit-' + computedDataEventTracking
          "
          @click="submitForm"
        >
          <p
            id="mailer-mobile-email-go"
            :class="computedSubmitTextStyle"
            :data-event-tracking="
              'newsletter-submit-' + computedDataEventTracking
            "
          >
            {{ subscribe }}
          </p>
          <div
            id="mailer-mobile-email-loader"
            class="mailer-loader"
            :class="computedLoaderStyle"
          ></div>
          <!-- <img
            id="mailer-mobile-email-success"
            :class="computedLoaderSuccess"
            src="https://d2duaskl0104ek.cloudfront.net/assets/books/images/success-blue.svg"
            alt="go icon"
          /> -->
          <div id="mailer-mobile-email-success" :class="computedLoaderSuccess">
            Sent
          </div>
        </button>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from "../common/PopupModal.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    PopupModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    source: {
      default: "",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    description: {
      default: "",
      type: String,
    },
    inputName: {
      default: "",
      type: String,
    },
    inputEmail: {
      default: "",
      type: String,
    },
    subscribe: {
      default: "",
      type: String,
    },
    showName: {
      default: false,
      type: Boolean,
    },
    isThai: {
      default: false,
      type: Boolean,
    },
    isAutoPopup: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      name: "",
      email: "",
      loading: false,
      success: false,
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters("profile", { referrerEmail: "email" }),
    computedWarningStyle() {
      return this.error ? "flex" : "hidden";
    },
    computedSubmitTextStyle() {
      return !this.loading && !this.success ? "block" : "hidden";
    },
    computedLoaderStyle() {
      return this.loading ? "block" : "hidden";
    },
    computedLoaderSuccess() {
      return this.success ? "block" : "hidden";
    },
    computedFormDisabled() {
      return !!(this.loading || this.success);
    },
    // invalidEmailErrorMessage() {
    //   return this.$t("newsletter.invalid-email");
    // },
    computedDataEventTracking() {
      return this.isAutoPopup ? "pop-up" : "CTA";
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
      this.error = false;
    },
    async submitForm() {
      if (this.email === "") {
        this.error = true;
        this.errorMessage = "Email is required.";
        return;
      }

      const emailRegex = new RegExp(
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
      );
      if (this.email !== "" && !emailRegex.test(this.email)) {
        this.error = true;
        this.errorMessage = "Please enter a valid email address.";
        return;
      }

      if (this.name === "") {
        this.error = true;
        this.errorMessage = "Name is required.";
        return;
      }

      this.error = false;
      this.loading = true;
      let res = null;
      try {
        let envURL = process.env.VUE_APP_NEWSLETTER_API_URL;
        const subscriptionObject = {
          name: this.name,
          email: this.email,
          referrerEmail: this.referrerEmail,
        };
        res = await axios.request(envURL, {
          method: "post",
          data: subscriptionObject,
        });

        if (res && res.status == 200) {
          this.success = true;
          this.loading = false;

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "newsletter_sign_up",
          });

          setTimeout(() => {
            this.success = false;
            this.$emit("closeModal");
            this.email = "";
            this.name = "";
          }, 3000);
        } else {
          this.loading = false;
          this.error = true;
          if (res && res.data && res.data.message) {
            this.errorMessage = res.data.message;
          }
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorMessage =
          "An error occurred while submitting the form. Please try again later.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Start of mailer modal */

.mailer-modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(11, 12, 15, 0.6); /* Black w/ opacity */
}

.mailer-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 95%;
  max-width: 340px;
  animation-name: fade;
  animation-duration: 0.4s;

  background: #f8f9f9;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  color: 0B0C0F;
  padding: 35px 20px;
}

#mailer-modal-close {
  position: absolute;
  right: 28px;
  top: 24px;
  cursor: pointer;
}

.mailer-modal-content .mailer-button-container input {
  width: 100% !important;
  border: 1px solid #99a4b2 !important;
}
.mailer-modal-content .mailer-button-container input:invalid {
  border: 2px solid #dd3333 !important;
  background-color: #ffe3e3;
}
// .mailer-modal-content .mailer-button-container input:valid {
//   border: 2px solid #a9e8d5 !important;
//   background-color: #dcfff5;
// }

.mailer-modal-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  align-self: flex-start;
  text-align: left !important;
}

.mailer-modal-text span {
  font-family: Gobold;
  display: inline-block;
  font-style: normal;
  font-size: 30px;
  line-height: 64px;
  color: #00a9e0;
  margin-top: -8px;
}

//Thai Title Section Start
.mailer-modal-text-thai {
  font-family: FC Minimal;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-top: -8px;
}

.mailer-modal-text-thai div span span {
  // G
  font-family: Gobold;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 43px;
  line-height: 65px;
  color: #00a9e0;
}

.mailer-modal-text-thai div span {
  // eneration
  font-family: Gobold;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 65px;
  color: #00a9e0;
}

.mailer-modal-text-thai div span span:last-child {
  // S
  font-family: Gobold;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 43px;
  line-height: 65px;
  color: white !important;
}

.mailer-modal-text-thai div span:last-child {
  // Thai Generations Text
  font-family: FC Minimal;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 65px;
  color: #00a9e0;
  margin-top: -22px;
}
//Thai Title Section End

.mailer-modal-text span span {
  font-size: 36px;
  line-height: 64px;
}

.mailer-modal-text span span:last-child {
  color: white !important;
}

.mailer-modal-policy-text {
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 28px;
  text-align: left !important;
}

.mailer-modal-policy-text a {
  text-decoration: underline;
  color: white !important;
}

.mailer-modal-policy-text-thai {
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 28px;
  text-align: left !important;
}

.mailer-modal-policy-text-thai a {
  text-decoration: underline;
  color: white !important;
}

#mailer-desktop-email-success,
#mailer-mobile-email-success {
  height: 21px;
  width: 50px;
  font-size: 19px;
  line-height: 24px;
  margin: 0 auto;
}

#mailer-modal-warning-container {
  flex-direction: row;
  margin-top: 8px;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: self-start;
}

#mailer-modal-warning-container img {
  margin-right: 4px;
  margin-top: 4px;
}

#mailer-modal-warning-container p {
  color: #dd3333;
  font-style: normal;
  font-weight: lighter;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mailer-loader,
.mailer-loader:after {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.mailer-loader {
  margin: 2px auto;
  font-size: 10px;
  position: relative;
  border-top: 0.3em solid rgba(20, 27, 77, 0.5);
  border-right: 0.3em solid rgba(20, 27, 77, 0.5);
  border-bottom: 0.3em solid rgba(20, 27, 77, 0.5);
  border-left: 0.3em solid #141b4d;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.mailer-modal-newsletter-text-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}

.mailer-modal-newsletter-text {
  margin-left: 8px;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 8px;
  padding-right: 24px;
}

#mailer-mobile-email-button {
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  min-height: 40px;
  font-size: 19px;
  line-height: 24px;
  padding: 12px 24px;
  background: #00a9e0;
  border-radius: 24px;
  border: 0;
  color: #0b0c0f;
  min-width: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#mailer-mobile-email-button:hover {
  background: #00a9e0;
}

#mailer-mobile-email-go {
  font-size: 19px;
  line-height: 24px;
  color: white;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 769px) {
  .mailer-modal-content {
    width: 500px;
    max-width: none;
    padding: 40px 30px 56px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .mailer-modal-content .mailer-button-container input {
    width: 416px !important;
  }

  .mailer-modal-text {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    padding-right: 0;
  }

  .mailer-modal-text span {
    font-family: Gobold;
    margin-left: 2px;
    font-style: normal;
    font-size: 40px;
    line-height: 64px;
    margin-top: 1px;
  }

  .mailer-modal-text span span {
    font-size: 48px;
    line-height: 64px;
  }

  .mailer-modal-text span span:last-child {
    color: #00a9e0;
  }

  // Thai Title Section
  .mailer-modal-text-thai div span span {
    // G
    font-family: Gobold;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 53px;
    line-height: 65px;
    color: #00a9e0;
  }

  .mailer-modal-text-thai div span {
    // eneration
    font-family: Gobold;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #00a9e0;
  }

  .mailer-modal-text-thai div span span:last-child {
    // S
    font-family: Gobold;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 53px;
    line-height: 65px;
    color: white !important;
  }

  .mailer-modal-text-thai div span:last-child {
    // Thai Generations Text
    font-family: FC Minimal;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 65px;
    color: #00a9e0;
    margin-top: -10px;
  }
  // Thai Title Section End

  .mailer-modal-policy-text {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 32px;
  }

  #mailer-modal-warning-container {
    justify-content: center;
    align-items: center;
  }

  .mailer-modal-newsletter-text {
    font-size: 14px;
    padding-right: 0;
  }

  #mailer-mobile-email-button {
    font-size: 20px;
    line-height: 24px;
    padding: 16px 32px;
    border-radius: 40px;
    border: 0;
    min-width: 154px;
    min-height: 48px;
  }

  #mailer-mobile-email-button:hover {
    background: #00a9e0;
  }

  #mailer-mobile-email-go {
    font-size: 20px;
    line-height: 32px;
  }
}

#mailer-modal-checkbox {
  position: absolute;
  opacity: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

#mailer-modal-checkbox + label span {
  margin-top: 2px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url("https://d2duaskl0104ek.cloudfront.net/assets/books/images/mailer-checkbox.svg");
}

#mailer-modal-checkbox:checked + label span {
  background: url("https://d2duaskl0104ek.cloudfront.net/assets/books/images/mailer-checkbox-checked.svg");
}

.mailer-button-container input {
  height: 48px;
  padding: 12px 20px;
  border-radius: 24px;
  border: 0;
  width: 300px;
  color: #0b0c0f;
}

.mailer-button-container input:disabled {
  background: white !important;
  color: #0b0c0f;
}
</style>
