import axios from "axios";

const INITIAL_STATE = {
  livevoiceSession: false,
  isRegistered: true,
};

const state = () => INITIAL_STATE;

const mutations = {
  setlivevoiceSession(state, session) {
    state.livevoiceSession = session;
  },
  setIsRegistered(state, isRegistered) {
    state.isRegistered = isRegistered;
  },
};

const actions = {
  async fetchLivevoiceSession({ commit, dispatch, rootState }, sessionId) {
    try {
      const accessToken = rootState.user.token;
      const { data: data } = await axios.get(
        process.env.VUE_APP_API_URL + "/livevoice/session/" + sessionId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setlivevoiceSession", data);
    } catch (e) {}
  },
  async checkRegistration({ commit, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/registration/delegate/check`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setIsRegistered", data);
    } catch (e) {
      console.error("Error fetching registration:", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
