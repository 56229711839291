<template>
  <div class="flex flex-col items-center text-grey-100 pb-[48px] lg:pb-[40px]">
    <p
      class="font-aktiv-grotesk-trial text-center mt-[24px] md:mt-[32px] text-[24px] leading-[32px]"
    >
      PhotoDrop
    </p>
    <p class="mt-[12px] text-[16px] leading-[24px] text-center">
      Have your photos dropped directly to you!
    </p>
    <div
      class="mt-[16px] md:mt-[24px] w-[287px] h-[287px] drop-shadow-photoqr bg-yellow rounded-[80px] flex items-center justify-center mx-auto"
    >
      <div
        class="bg-white rounded-[60px] h-[247px] w-[247px] flex items-center justify-center"
      >
        <qrcode-vue
          :value="qrcodeValue"
          :size="190"
          level="H"
          data-test-id="gensapp-photodrop-qrcode"
          v-bind:class="{ 'shadow-[0_0_10px_5px_#a7a7a7]': this.isSamsung }"
        />
      </div>
    </div>
    <p
      class="mt-[20px] md:mt-[24px] text-[16px] leading-[24px] max-w-[327px] md:max-w-[436px] flex flex-row"
    >
      <span>1. </span>
      <span class="ml-[12px]"
        >Show this QR Code to any HOGC photographer wearing a white "PhotoDrop"
        shirt! 👕📸</span
      >
    </p>
    <p
      class="mt-[24px] text-[16px] leading-[24px] max-w-[327px] md:max-w-[436px] flex flex-row"
    >
      <span>2. </span>
      <span class="ml-[12px]"
        >Grab your friends, get your photo taken, and come back here for the
        picture!</span
      >
    </p>
    <!-- <div class="w-full max-w-[327px] md:max-w-[436px]">
      <div
        class="mt-[20px] md:mt-[24px] max-w-[327px] md:max-w-[436px] mb-[80px] md:mb-0"
      >
        <Collapsible
          :icon="require('/public/assets/icons/lightbulb.svg')"
          :data-test-id="'gensapp-photodrop-funfact'"
          title="Fun fact"
        >
          <p class="text-[16px] leading-[24px]">
            The PhotoDrop feature was initiated and developed by a team of
            university volunteers!
          </p>
        </Collapsible>
      </div>
    </div> -->
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapState } from "vuex";
// import Collapsible from '@/components/common/Collapsible'

export default {
  name: "PhotosQrCode",
  components: {
    QrcodeVue,
    // Collapsible,
  },
  mounted() {
    if (navigator.userAgent.match(/samsung/i)) {
      this.isSamsung = true;
      console.log("You're samsung");
    } else {
      console.log("You're not samsung");
    }
  },
  computed: {
    ...mapState("profile", ["auth0IdPrefixed", "hashId"]),
    qrcodeValue() {
      return "https://gens.bio/" + this.hashId;
    },
  },
};
</script>
