<template>
  <PopupModal :show="show" @close-popup="toggleQr()">
    <div
      id="photo-panel"
      class="bg-white w-full h-full md:w-[375px] md:min-h-0 md:max-h-[calc(100vh-140px)] top-[50%] left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-col items-center md:rounded-[24px] mx-auto fixed inset-0 z-50 scrollbar-hide overflow-y-scroll"
      @wheel.stop="onScroll"
      @touchmove.stop="onScroll"
      @scroll.stop="onScroll"
      @click.stop
    >
      <div class="flex flex-col items-center px-[12px] xs:px-[24px]">
        <div class="w-full h-full md:w-[327px] md:h-auto">
          <div
            class="bg-white pt-[72px] w-[90%] md:w-[inherit] flex justify-end fixed"
          >
            <img
              class="mt-[6px] w-[20px] h-[20px] cursor-pointer mr-[12px]"
              src="/assets/icons/cross.svg"
              data-test-id="gensapp-photodrop-toggleqr"
              @click="toggleQr"
            />
          </div>
          <PhotosQrCode class="pt-[72px]" />
        </div>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PhotosQrCode from "@/components/Photo/PhotosQrCode";
import PopupModal from "@/components/common/PopupModal";

export default {
  name: "PhotosModal",
  components: {
    PhotosQrCode,
    PopupModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleQr() {
      this.$store.dispatch("photos/updateIsShowQr", false);
      this.$emit("close-modal");
    },
    onScroll(event) {
      const el = document.getElementById("photo-panel");
      const maxScroll = el.scrollHeight - el.clientHeight;
      if (
        (el.scrollTop === maxScroll && event.deltaY > 0) ||
        (el.scrollTop === 0 && event.deltaY < 0)
      )
        event.preventDefault();
    },
  },
};
</script>

<style scoped>
.modal-background {
  background-color: rgb(0, 0, 0, 0.5);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
