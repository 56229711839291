<template>
  <master-layout id="content-experience" v-slot="slotProps" ref="masterLayout">
    <div v-show="!isLoading" class="lg:w-[1400px]">
      <div
        v-if="signups"
        class="font-aktiv-grotesk max-w-full overflow-hidden flex flex-col pt-[16px] md:pt-[40px] pb-[92px]"
        :class="{ '!pt-[60px]': isIos() }"
      >
        <button ref="hiddenButton" @click="closeExpModal(slotProps)"></button>
        <div class="fixed bottom-[88px] z-50">
          <CheatConsole v-if="isNonProd"></CheatConsole>
        </div>
        <div class="mb-6 px-[24px] md:px-8 lg:px-[56px]">
          <img
            src="../../../public/assets/icons/go-back-chevron.svg"
            alt="Back Icon"
            class="cursor-pointer"
            data-test-id="gensapp-experiencesignup-back"
            @click="back()"
          />
        </div>

        <section class="flex flex-col mb-3 px-6 md:px-8 lg:px-[56px]">
          <h1
            class="text-[32px] leading-[40px] font-aktiv-grotesk-medium mb-[4px] font-aktiv-grotesk-medium"
          >
            Experiences
          </h1>
        </section>
        <div
          class="flex"
          :class="
            hasSignupBefore || overallStatus === 'Unavailable'
              ? 'flex-col-reverse mt-[21px]'
              : 'flex-col'
          "
        >
          <section
            class="flex mb-12 mx-6 md:mx-8 lg:mx-[56px] flex-col md:flex-row"
          >
            <div
              class="flex flex-shrink-0 md:max-w-[324px] md:mr-8 md:items-center"
              :class="{
                'flex-col-reverse !max-w-none !mx-auto md:w-[480px]':
                  hasSignupBefore || overallStatus === 'Unavailable',
                'flex-col': !(
                  hasSignupBefore || overallStatus === 'Unavailable'
                ),
              }"
            >
              <p class="text-[16px] leading-[24px] font-aktiv-grotesk mb-3">
                {{ this.mainExpVid.videoDescription }}
              </p>
              <div
                class="relative exp-video-aspect w-full min-w-[272px]"
                :class="{
                  'max-w-none mx-auto mb-4':
                    hasSignupBefore || overallStatus === 'Unavailable',
                  'flex-col': !(
                    hasSignupBefore || overallStatus === 'Unavailable'
                  ),
                }"
              >
                <video-player
                  v-if="this.mainExpVid.trailerVideo"
                  ref="experienceTrailerVideo"
                  class="rounded-2xl w-full min-w-[272px]"
                  data-event-tracking="experience-video-player"
                  :options="getVideoOptions"
                />
                <div
                  ref="preview"
                  class="absolute top-0 w-full rounded-2xl exp-video-aspect cursor-pointer flex flex-col items-center"
                >
                  <img
                    :src="this.mainExpVid.trailerVideoThumbnail"
                    class="rounded-2xl w-full exp-video-aspect"
                    data-test-id="experience-video-preview"
                    @click="playVideo"
                  />
                  <div
                    class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-2xl"
                  >
                    <img
                      src="../../../public/assets/icons/youtube-player/play-button-dark.svg"
                      data-test-id="experience-video-play"
                      class="w-[54px] h-[54px]"
                      @click="playVideo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ExperienceListPanel
            v-if="!hasSignupBefore && overallStatus !== 'Unavailable'"
            :signups="signups"
            class="mb-16"
          ></ExperienceListPanel>
          <!-- <p
          v-if="!isSignupClosed()"
          class="rounded-lg bg-grey-10 mb-6 text-grey-80 font-aktiv-grotesk-regular text-[16px] leading-[24px] px-4 py-3"
        >
          {{ getSignUpText() }}
        </p> -->
          <section id="expCarousel">
            <div
              v-if="
                isEditEnable &&
                overallStatus !== 'Unavailable' &&
                !hasSignupBefore
              "
              class="w-auto mx-6 md:mx-8 lg:mx-[56px] flex mb-3"
            >
              <div
                class="text-[12px] leading-[20px] font-aktiv-grotesk-medium text-white bg-orange rounded-full py-[2px] px-2"
              >
                Step 1
              </div>
            </div>
            <div ref="experiencesSection" class="header-scroll-margin">
              <h2
                class="mb-4 text-[24px] leading-[32px] font-aktiv-grotesk-medium px-6 md:px-8 lg:px-[56px]"
              >
                Learn more about all 9 Experiences
              </h2>
              <ExperienceCarousel
                id="exp-page-exp"
                ref="exp-page-exp"
                baseSwiperId="exp-page-exp"
                :swiperOptions="swiperOptions"
                @show-popup="triggerExpPopup($event, slotProps)"
                :expData="expData"
              ></ExperienceCarousel>
            </div>
          </section>
          <section class="mb-12">
            <MyExperienceSection
              :is-edit-enable="isEditEnable"
              :signups="signups"
              :allocatedExpsNo="this.mainExpVid.allocatedExpsNo"
              :expData="expData"
              @update="updateExps"
            />
            <button
              v-if="!isEditEnable && !isSignupClosed()"
              class="font-aktiv-grotesk-trial block mx-auto rounded-[100px] text-white text-[20px] leading-[24px] text-center py-[12px] px-5 mb-6 mt-6 bg-orange hover:bg-[#F27521] cursor-pointer w-3/4"
              @click="toggleEdit"
            >
              {{ getSubmitBtnText() }}
            </button>
            <button
              v-else-if="!isSignupClosed()"
              class="font-aktiv-grotesk-trial block mx-auto rounded-[100px] text-white text-[20px] leading-[24px] text-center py-[12px] px-5 mb-6 mt-6 bg-orange hover:bg-[#F27521] cursor-pointer w-3/4"
              @click="goToExpSummary()"
            >
              Continue
            </button>
            <ExperienceListPanel
              v-if="
                (hasSignupBefore && !isEditEnable) ||
                overallStatus === 'Unavailable'
              "
              :signups="signups"
            ></ExperienceListPanel>
            <div
              v-if="
                overallStatus === 'Confirmed' || overallStatus === 'Unavailable'
              "
              class="mb-12 mx-6 md:mx-8 lg:mx-[56px]"
            >
              <div
                class="bg-white rounded-2xl p-4 mt-16 border-[1px] border-[#FF6600]"
              >
                <h2
                  class="text-[16px] leading-[24px] font-aktiv-grotesk-medium font-normal"
                >
                  💡Pro-tip: <br />
                  Make the most of Experiences with these reflection questions:
                </h2>
                <ol
                  class="list-decimal text-[16px] leading-[24px] mt-2 list-outside px-5"
                >
                  <li>What can I implement in my church?</li>
                  <li>How can this be applied within my church's context?</li>
                </ol>
              </div>
            </div>
            <div
              v-if="!hasSignupBefore && overallStatus !== 'Unavailable'"
              class="mt-16 mb-12 mx-6 md:mx-8 lg:mx-[56px]"
            >
              <div
                class="bg-white rounded-2xl p-4 mt-3 border-[1px] border-[#FF6600]"
              >
                <h2
                  class="text-[16px] leading-[24px] font-aktiv-grotesk-medium font-normal"
                >
                  💡Pro-tip: <br />
                  Teamwork makes the dream work
                </h2>
                <p class="text-[16px] leading-[24px] text-grey-100">
                  If you're in a team, consider signing up for different
                  experiences within the team. You can share your learnings with
                  one another!
                </p>
              </div>
            </div>
          </section>
          <!-- <InterestModal
          :show="showModal"
          @close-modal="closeModal"
        ></InterestModal> -->
          <!-- Sprint 15 Refactor: Extract out as a component start -->
          <!-- <CancelModal
          :show="showCancelModal"
          @close-modal="closeModal"
        ></CancelModal> -->
        </div>
      </div>
    </div>
    <div
      v-show="isLoading"
      class="w-screen h-screen flex items-center justify-center"
    >
      <loading-state />
    </div>
  </master-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { toRaw } from "vue";
import CheatConsole from "../../components/common/CheatConsole.vue";
// import InterestModal from "../../components/ExperiencePage/InterestModal.vue";
// import CancelModal from "../../components/ExperiencePage/CancelModal.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import MyExperienceSection from "@/components/ExperiencePage/MyExperienceSection.vue";
import ExpStatusPanel from "@/components/ExperiencePage/ExpStatusPanel.vue";
import ExperienceCarousel from "@/components/ExperiencePage/ExperienceCarousel.vue";
import ExperienceListPanel from "@/components/ExperiencePage/ExperienceListPanel.vue";
import axios from "axios";
import moment from "moment";
import store from "@/store/index";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import LoadingState from "@/components/common/LoadingState.vue";

export default {
  name: "ExperiencePage",
  components: {
    CheatConsole,
    // InterestModal,
    // CancelModal,
    VideoPlayer,
    MyExperienceSection,
    ExpStatusPanel,
    ExperienceCarousel,
    ExperienceListPanel,
    LoadingState,
  },
  data() {
    return {
      experienceSlugs: ["exp-page-exp"],
      testvideo:
        "https://cdn.generationsmvmt.com/videos/conference-app/exp-trailer.mp4",
      cancelModalType: "",
      signupModalType: "",
      showModal: false,
      showCancelModal: false,
      showModal2: false,
      isEditEnable: false,
      isLoading: false,
      // trailerVideoUrl:
      //   "https://cdn.generationsmvmt.com/videos/conference-app/exp-trailer.mp4",
      // experienceTrailerBanner:
      //   "https://cdn.generationsmvmt.com/images/app/exp-trailer-thumbnail.jpg",
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
      },
      experienceTourStep0Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Experiences</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Go behind the scenes and see GenerationS & Strong Church principles come to life</span>`,
      experienceTourStep1Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">View Details</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Learn more about each of our 9 Experiences.</span>`,
      experienceTourStep2Text: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Sign-up for Custom Experiences</span> <br> <br>
            <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Slots are limited so rank your preferences and we'll allocate 2 custom experiences per delegate!</span>`,
    };
  },
  computed: {
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS", "EXP_ALLOC_TS"]),
    ...mapGetters("profile", ["type"]),
    ...mapState("signup", {
      signups: "signups",
      pending_signups: "pending_signups",
      backFromSummary: "backFromSummary",
      successFromSummary: "successFromSummary",
      expData: "expData",
      mainExpVid: "mainExpData",
    }),
    ...mapState("user", ["token"]),
    ...mapState("timer", ["currDate"]),
    ...mapState("profile", ["hasCompletedExperienceTour"]),

    // finalExperiences() {
    //   if (!this.signups) return Object.values(this.experiences);
    //   const finalExps = { ...this.experiences };
    //   for (const key in this.signups) {
    //     const currentExp = this.signups[key];
    //     if (!currentExp) continue;
    //     if (!finalExps[key].status && currentExp.status) {
    //       finalExps[key].status = currentExp.status;
    //     }
    //     finalExps[key].ranking = currentExp.ranking;
    //     if (key === "CG") {
    //       if (currentExp.location) {
    //         finalExps[key].location = currentExp.location;
    //       }
    //       if (currentExp.expTime) {
    //         finalExps[key].timing = currentExp.expTime;
    //       }
    //     }
    //   }

    // return finalExps;
    // if (this.isEditEnable) return Object.values(finalExps);

    // // filter away the not selected status
    // const checkAgainstStatus =
    //   this.overallStatus == "Submitted Interest"
    //     ? "Pending"
    //     : this.overallStatus;
    // const filteredExps = Object.values(finalExps).filter(
    //   (exp) => exp.status === checkAgainstStatus
    // );
    // return filteredExps;
    // },
    isNonProd() {
      return process.env.VUE_APP_NODE_ENV !== "production";
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    getVideoOptions() {
      return {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: this.mainExpVid.trailerVideo,
            type: "video/mp4",
          },
        ],
      };
    },
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    signupsByRank() {
      let signupArray = Object.values(this.signups);
      // remove null element
      // remove those is unavaliable status

      signupArray = signupArray.filter((element) => {
        return element && element.status !== "Unavailable";
      });

      signupArray.sort((a, b) => {
        if (a.ranking < b.ranking) return -1;
        if (a.ranking > b.ranking) return 1;
        return 0;
      });

      console.log("SIGN UP ARRARY", signupArray);

      return signupArray;
    },
    // displayTypes() {
    //   if (!this.signupsByRank.length) {
    //     return ["CG", "DSN", "MCR", "MD", "LM", "MOH"];
    //   }

    //   let registeredTypes = [];
    //   registeredTypes = this.signupsByRank.map((element) => {
    //     return element.type;
    //   });

    //   const existingTypes = ["LM", "MOH"];
    //   existingTypes.forEach((type) => {
    //     registeredTypes.push(type);
    //   });
    //   return registeredTypes;
    // },
    isExperiencesAnchorTagPresentInTheUrl() {
      if (this.$route.hash) {
        return this.experienceSlugs.includes(this.$route.hash.slice(1));
      }
      return false;
    },
  },
  // create()
  async ionViewDidEnter() {
    this.isLoading = true;
    await this.$store.dispatch("signup/fetchSignup");
    await this.$store.dispatch("signup/fetchDates");
    this.isEditEnable = !this.hasSignupBefore;
    if ("edit" in this.$route.query) this.isEditEnable = true;
    if (this.backFromSummary) {
      this.isEditEnable = true;
      this.setBackFromSummary(false);
    }

    await this.$store.dispatch("announcements/refreshAnnouncements");
    await this.$store.dispatch("announcements/fetchUnreadCount");

    setTimeout(() => {
      if (!this.hasCompletedExperienceTour && this.signupCheck()) {
        if (this.type === "SCS") {
          this.loadExperienceTour();
        }
      }
    }, 100);

    setTimeout(() => {
      this.updateCarousel();
    }, 1800);

    FirebaseAnalytics.setScreenName({
      screenName: "experience",
      nameOverride: "ExperiencePage",
    });

    await this.getExperiences();
    this.populateExperienceSlugs();
    this.isLoading = false;

    this.$nextTick(() => {
      if (this.isExperiencesAnchorTagPresentInTheUrl) {
        this.scrollToFindOutMoreAboutExperiencesSection();
        const experienceFromAnchorTagPresentInTheUrl =
          this.getExperienceFromSlug(this.$route.hash.slice(1));
        // Need to use toRaw as $refs.masterLayout is a proxy
        toRaw(this.$refs.masterLayout).toggleExpModal(
          experienceFromAnchorTagPresentInTheUrl,
          false
        );
      }
    });
  },

  async ionViewDidLeave() {
    this.pauseVideo();
    this.$refs.hiddenButton.click();
    if (this.successFromSummary) {
      this.setSuccessFromSummary(false);
    }
  },
  async updated() {
    await this.$nextTick();
  },

  methods: {
    ...mapActions("signup", [
      "removeSignupState",
      "setBackFromSummary",
      "setSuccessFromSummary",
    ]),
    ...mapActions("tour", ["setTour"]),
    closeExpModal(slotProps) {
      console.log("yesah");
      slotProps.closeExp();
    },
    getModalShow(modal) {
      this.showModal = modal;
    },
    // getCancelModalShow(modal, type) {
    //   this.showCancelModal = modal;
    //   this.cancelModalType = type;
    //   this.confirmCancel(type);
    // },
    closeModal() {
      this.showModal = false;
      // this.showCancelModal = false;
    },
    isIos() {
      return isIosNativeOnly();
    },
    goToSchedule() {
      this.showModal = false;
      // this.showCancelModal = false;
      this.$router.push("/schedule");
    },
    // Sprint 15 Refactor: can rename number to more specific name - end
    back() {
      if (this.isEditEnable) {
        this.toggleEdit();
        return;
      }
      if (this.successFromSummary) {
        this.$router.push("/home");
        return;
      }

      this.$router.back();
    },
    backToHome() {
      this.$router.push("/home");
      this.tour.cancel();
      this.tour.getById("behind-the-scenes");
    },
    backToTop() {
      const elem = document.getElementById("content-experience");
      elem.scrollToTop(1000);
    },
    // async confirmCancel(type) {
    //   try {
    //     await axios.request(
    //       process.env.VUE_APP_API_URL + `/exps/signup/${type}`,
    //       {
    //         method: "delete",
    //         headers: {
    //           Authorization: `Bearer ${this.token}`,
    //         },
    //       }
    //     );
    //   } catch (error) {
    //     location.reload();
    //   }
    //   this.removeSignupState(type);
    //   this.experiences[type].status = "";
    //   if (type === "CG") {
    //     this.experiences[type].location = "To Be Assigned";
    //   }
    // },
    async getExperiences() {
      await this.$store.dispatch("signup/fetchExpData");
      await this.$store.dispatch("signup/fetchMainExpData");
    },
    updateExps(exps) {
      console.log("here is updateExps:", exps);
      this.expsDraggable = exps;
    },
    registerSignUp(exp) {
      const { type } = exp;
      this.experiences[type].status = "Pending";
    },
    // confirmCancelDataEventTracking(type) {
    //   const dataEventTrackingMap = {
    //     CG: "gensapp-experiencesignup-cgexperience-cancelpopup-cancelbutton",
    //     SPE: "gensapp-experiencesignup-serviceprepexperience-cancelpopup-cancelbutton",
    //     MD: "gensapp-experiencesignup-ministrydebriefs-cancelpopup-cancelbutton",
    //   };
    //   return dataEventTrackingMap[type];
    // },
    // stayDataEventTracking(type) {
    //   const dataEventTrackingMap = {
    //     CG: "gensapp-experiencesignup-cgexperience-cancelpopup-staybutton",
    //     SPE: "gensapp-experiencesignup-serviceprepexperience-cancelpopup-staybutton",
    //     MD: "gensapp-experiencesignup-ministrydebriefs-cancelpopup-staybutton",
    //   };
    //   return dataEventTrackingMap[type];
    // },
    // async signed() {
    //   const { status, data } = await axios.request(
    //     process.env.VUE_APP_API_URL + "/exps/signup",
    //     {
    //       method: "post",
    //       // change to receive a list of exp types
    //       data: { types: ["CG", "MD", "SPE", "TEST"] },
    //       // data: { types: [this.exp.type] },
    //       headers: {
    //         Authorization: `Bearer ${this.token}`,
    //       },
    //     }
    //   );
    //   if (status !== 201) location.reload();
    //   this.setSignupState(data);

    //   this.$emit("sign-up");
    // },
    isSignupClosed() {
      // needs refectoring (we are just using CG signup close as of now)
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      // console.log("signUpExpiry: " + signUpExpiry);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
    hasSignedUpForExp() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    signupCheck() {
      if (!this.isSignupClosed() && !this.hasSignedUpForExp()) {
        //display signups
        console.log("display signups");
        return true;
      }
      //do not display signups
      console.log("do not display signups");
      return false;
    },
    toggleEdit() {
      this.isEditEnable = !this.isEditEnable;
    },
    getSignUpText() {
      if (this.isSignupClosed()) return "Sign up is closed.";

      const dayStr = moment(this.SIGN_UP_CLOSE_TS).format(
        "ddd, DD MMM, hh:mm A"
      );
      return "Indicate your interest by " + dayStr + ".";
    },
    getSubmitBtnText() {
      return this.hasSignupBefore ? "Edit my preferences" : "Continue";
    },
    goToExpSummary() {
      this.$router.push("/experience/summary");
    },
    async loadTour() {
      console.log("load tour: " + this.tour.getCurrentStep().id);
      if (this.tour.getCurrentStep().id) {
        this.tour.next();
      }
    },
    async skipExperienceTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-skip-experience",
          page: "experience",
        },
      });
      const payload = { hasCompletedExperienceTour: true };
      await this.$store.dispatch("profile/setExperienceTourStatus", payload);
      store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    async endExperienceTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-end-experience",
          page: "experience",
        },
      });
      const payload = { hasCompletedExperienceTour: true };
      await this.$store.dispatch("profile/setExperienceTourStatus", payload);
      store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    startExperienceTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-start-experience",
          page: "experience",
        },
      });
      this.tour.next();
    },
    backToWelcome() {
      this.backToTop();
      this.tour.back();
    },
    loadExperienceTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: "bg-yellow",
          cancelIcon: {
            enabled: true,
          },
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              // progress.innerText = `Step ${
              //   this.tour.steps.indexOf(currentStep) + 1
              // } of ${this.tour.steps.length}`;
              progress.innerText = `Step ${this.tour.steps.indexOf(
                currentStep
              )} of ${this.tour.steps.length - 1}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = { hasCompletedExperienceTour: true };
              store.dispatch("profile/setExperienceTourStatus", payload);
              store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "experience-welcome",
          attachTo: {
            on: "top",
          },
          buttons: [
            {
              text: "Skip",
              action: this.skipExperienceTour,
              secondary: true,
            },
            {
              text: "Start Tour",
              action: this.startExperienceTour,
            },
          ],
          highlightClass: "highlight",
          text: this.experienceTourStep0Text,
          scrollTo: { behavior: "smooth", top: "0px" },
          canClickTarget: false,
          cancelIcon: {
            enabled: false,
          },
        },
        {
          id: "experience-carousel",
          attachTo: {
            element: "#experience-carousel",
            on: "bottom",
          },
          buttons: [
            {
              text: "Back",
              action: this.backToWelcome,
              secondary: true,
            },
            {
              text: "Next",
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.experienceTourStep1Text,
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          cancelIcon: {
            enabled: true,
          },
        },
        {
          id: "experience-choices",
          attachTo: {
            element: "#experience-choices",
            on: "bottom",
          },
          text: this.experienceTourStep2Text,
          buttons: [
            {
              text: "Back",
              action: this.tour.back,
              secondary: true,
            },
            {
              text: "End",
              action: this.endExperienceTour,
            },
          ],
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          cancelIcon: {
            enabled: true,
          },
        },
      ];

      this.tour.addSteps(steps);
      this.tour.start();
      this.setTour(this.tour);
    },
    triggerExpPopup(exp, slotProps) {
      this.pauseVideo();
      slotProps.toggleExp(exp, false);
    },
    updateCarousel() {
      if (this.$refs["exp-page-exp"]) this.$refs["exp-page-exp"].update();
    },
    playVideo() {
      this.$refs.experienceTrailerVideo.player.play();
      this.$refs.preview.classList.add("z-[-100]");
    },
    pauseVideo() {
      this.$refs.experienceTrailerVideo.player.pause();
    },
    scrollToFindOutMoreAboutExperiencesSection() {
      this.$refs.experiencesSection.scrollIntoView({ behavior: "smooth" });
    },
    populateExperienceSlugs() {
      for (const experienceObjectKey in this.expData) {
        this.experienceSlugs.push(this.expData[experienceObjectKey].slug);
      }
    },
    getExperienceFromSlug(slug) {
      for (const experienceObjectKey in this.expData) {
        if (this.expData[experienceObjectKey].slug === slug) {
          return this.expData[experienceObjectKey];
        }
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

::v-deep(.swiper-slide:last-child) {
  margin-right: 48px;
}

/** Sprint 15 Refactor: convert to tailwind css - start */
.center-cancel {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  background-color: #f8f9f9;
  border-radius: 16px;
  width: 327px;
  height: 324px;
  margin: auto;
}

.center-thank-you {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  background-color: #f8f9f9;
  border-radius: 16px;
  width: 327px;
  height: 296px;
  margin: auto;
}

.exp-video-aspect {
  aspect-ratio: 327/184;
}

/* For Tablets */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .center-cancel {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 324px;
    margin: auto;
  }

  .center-thank-you {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 296px;
    margin: auto;
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: 64px;
  }

  ::v-deep(.swiper-wrapper) {
    padding-left: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .center-cancel {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 324px;
    margin: auto;
  }

  .center-thank-you {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 296px;
    margin: auto;
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: 112px;
  }

  ::v-deep(.swiper-wrapper) {
    padding-left: 56px;
  }
}

.header-scroll-margin {
  scroll-margin: 100px;
}
/** Sprint 15 Refactor: convert to tailwind css - end */
</style>
